/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { MESSAGES } from '../../messages';
import './Fallback.css';
import noChatSelectedImage from '../../assets/images/chat_list2.png';
import noSearchResultsImage from '../../assets/images/chat_list1.png';

export enum FallbackType {
	NO_CHAT_LIST = 'NO_CHAT_LIST',
	NO_CHAT_SELECTED = 'NO_CHAT_SELECTED',
	NO_CHAT_AFTER_SEARCH = 'NO_CHAT_AFTER_SEARCH',
	FORBIDDEN = 'FORBIDDEN',
	GENERIC = 'GENERIC',
	NO_ACTIVE_CHATS = 'NO_ACTIVE_CHATS',
	NO_RECENT_CHATS = 'NO_RECENT_CHATS',
}

interface props {
	type: string | null;
}

export const Fallback = ({ type = null }: props): JSX.Element => {
	let title = 'Failed to load';
	let subtitle = "Something went wrong or there's no internet connection. Try again later.";
	let imageUrl = '';

	switch (type) {
		case FallbackType.NO_ACTIVE_CHATS:
			title = MESSAGES.NO_ACTIVE_CHATS;
			subtitle = '';
			break;
		case FallbackType.NO_RECENT_CHATS:
			title = MESSAGES.NO_RECENT_CHATS;
			subtitle = '';
			break;
		case FallbackType.NO_CHAT_LIST:
			title = MESSAGES.NO_CHAT_LIST_TITLE;
			subtitle = MESSAGES.NO_CHAT_LIST_SUBTITLE;
			imageUrl = noSearchResultsImage;
			break;
		case FallbackType.NO_CHAT_SELECTED:
			title = MESSAGES.NO_CHAT_SELECTED_TITLE;
			subtitle = MESSAGES.NO_CHAT_SELECTED_SUBTITLE;
			imageUrl = noChatSelectedImage;
			break;
		case FallbackType.NO_CHAT_AFTER_SEARCH:
			title = MESSAGES.NO_CHAT_LIST_AFTER_SEARCH_TITLE;
			subtitle = MESSAGES.NO_CHAT_LIST_AFTER_SEARCH_SUBTITLE;
			imageUrl = noSearchResultsImage;
			break;
		case FallbackType.FORBIDDEN:
			title = MESSAGES.FORBIDDEN_TITLE;
			subtitle = MESSAGES.FORBIDDEN_ERROR;
			break;
		case FallbackType.GENERIC:
		default:
			title = MESSAGES.GENERIC_TITLE;
			subtitle = MESSAGES.GENERIC_CHAT_ERROR;
			break;
	}
	return (
		<div className="fallback-container">
			{imageUrl && <img src={imageUrl} className="fallback-image" alt="" />}
			<div className="fallback-title">{title}</div>
			{/* eslint-disable-next-line react/no-danger */}
			<div className="fallback-subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />
		</div>
	);
};
