/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
	Box,
	createTheme,
	CssBaseline,
	Grid,
	Link,
	Paper,
	TextField,
	ThemeProvider,
	Typography,
	Button,
	Avatar,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { emailRegex } from '../../../configs';
import { ApiService } from '../../../services/ApiService';
import { StorageService } from '../../../services/StorageService';
import Toast from '../../Toast/Toast';
import { ToastProperties } from '../../../types';
import { STATUS } from '../../../enums/status.enum';
import { MESSAGES } from '../../../messages';

export const Login = () => {
	// States
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<{ email: string; password: string }>({ defaultValues: { email: '', password: '' } });
	const [toastProperties, setToastProperties] = useState<ToastProperties>({
		open: false,
		isError: false,
		message: '',
	});
	const theme = createTheme();

	// Init
	useEffect(() => {
		const { accessToken } = StorageService;
		if (accessToken) {
			navigate('/');
		}
	});

	// API calls
	const login = handleSubmit(async (data) => {
		try {
			setIsLoading(true);
			const response = await ApiService.login(data);
			const tokens = response.data.data.map((d) => d.access_token);
			StorageService.allAccessTokens = tokens;
			const userProfiles = await Promise.all(
				tokens.map(async (token) => ApiService.fetchUserInfo(token)),
			);
			// Check if reset password
			if (!userProfiles[0].passwordResetRequired) {
				const userTenants = await Promise.all(
					tokens.map(async (token) => ApiService.fetchTenantConfig(true, token)),
				);
				const allTenantConfigs = tokens.map((token, idx) => ({
					token,
					tenant_config: userTenants[idx],
					user_profile: userProfiles[idx],
				}));
				StorageService.allTenantConfigs = allTenantConfigs;
				const isUserDisabled = allTenantConfigs.every(
					(config) => config.user_profile.status === STATUS.DISABLED,
				);
				if (isUserDisabled) {
					StorageService.clearAuthData();
					setToastProperties({
						open: true,
						isError: true,
						message: MESSAGES.DISABLED_USER_ACCOUNT,
					});
				} else {
					navigate('/');
				}
			} else {
				// eslint-disable-next-line prefer-destructuring
				StorageService.accessToken = tokens[0];
				navigate('/reset-password');
			}
		} catch (error) {
			const typedError = error as unknown as AxiosError;
			if (typedError?.response?.status === 401) {
				setToastProperties({ message: 'Invalid Login credentials', isError: true, open: true });
			} else {
				setToastProperties({ message: 'Some unknown error occured', isError: true, open: true });
			}
		} finally {
			setIsLoading(false);
		}
	});

	return (
		<ThemeProvider theme={theme}>
			<Grid container component="main" sx={{ height: '100vh' }}>
				<CssBaseline />
				<Grid
					item
					xs={false}
					sm={4}
					md={7}
					sx={{
						backgroundImage: 'url(https://cdn.ischoolconnect.com/chatbot/live-agent-welcome.svg)',
						backgroundRepeat: 'no-repeat',
						backgroundColor: (t) =>
							t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
						backgroundSize: 'cover',
						backgroundPosition: 'center',
					}}
				/>
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}>
						<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							Sign in
						</Typography>
						<Box component="form" noValidate onSubmit={login} sx={{ mt: 1 }}>
							<Controller
								name="email"
								control={control}
								rules={{ required: true, pattern: emailRegex, maxLength: 191 }}
								render={({ field }) => (
									<TextField
										margin="normal"
										required
										fullWidth
										id="email"
										label="Email Address"
										name="email"
										autoComplete="email"
										autoFocus
										value={field.value}
										onChange={(em) => field.onChange(em.target.value)}
										error={!!errors.email?.type || false}
										helperText={
											(errors.email?.type === 'required' &&
												'Please enter your registered email ID') ||
											(errors.email?.type === 'pattern' && 'Please enter a valid email ID') ||
											(errors.email?.type === 'maxLength' && 'Max 191 characters are allowed')
										}
									/>
								)}
							/>
							<Controller
								name="password"
								control={control}
								rules={{ required: true, minLength: 8, maxLength: 30 }}
								render={({ field }) => (
									<TextField
										margin="normal"
										required
										fullWidth
										name="password"
										label="Password"
										type="password"
										id="password"
										autoComplete="current-password"
										value={field.value}
										onChange={(pass) => field.onChange(pass.target.value)}
										error={!!errors.password?.type || false}
										helperText={
											(errors.password?.type === 'required' && 'Please enter your password') ||
											(errors.password?.type === 'minLength' &&
												'Password has to be at least 8 characters') ||
											(errors.password?.type === 'maxLength' &&
												'Password can be maximum 30 characters')
										}
									/>
								)}
							/>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 2, textTransform: 'capitalize', background: '#00AFF0' }}
								disabled={isLoading}>
								{isLoading ? 'Loading...' : 'Sign In'}
							</Button>
							<Grid container>
								<Grid item xs>
									<Link href="/forgot-password" variant="body2">
										Forgot password?
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Grid>
				<Toast
					open={toastProperties.open}
					isError={toastProperties.isError}
					message={toastProperties.message}
					onClose={() => setToastProperties({ ...toastProperties, open: false })}
				/>
			</Grid>
		</ThemeProvider>
	);
};
