/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { FC, useState } from 'react';
import { MESSAGES } from '../../../messages';
import { ApiService } from '../../../services/ApiService';
import { ToastProperties } from '../../../types';
import { Loader } from '../../Loader/Loader';
import Toast from '../../Toast/Toast';

interface props {
	open: boolean;
	roomToBeClosed: string | null;
	closeModal: () => void;
}

const EndChatModal: FC<props> = ({ closeModal, roomToBeClosed, open }: props) => {
	const [isModalLoading, setisModalLoading] = useState<boolean>(false);
	const [toastProperties, setToastProperties] = useState<ToastProperties>({
		open: false,
		message: '',
		isError: false,
	});
	const endChat = async () => {
		try {
			setisModalLoading(true);
			if (roomToBeClosed) {
				await ApiService.closeRoom(roomToBeClosed);
			}
		} catch (error) {
			setToastProperties({ open: true, message: MESSAGES.GENERIC_ERROR, isError: true });
		} finally {
			setisModalLoading(false);
			closeModal();
		}
	};

	return (
		<>
			<Toast
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...toastProperties}
				onClose={() => setToastProperties({ ...toastProperties, open: false })}
			/>
			<Dialog open={open} onClose={() => closeModal()}>
				<DialogContent
					sx={{
						background: '#F9FAFB',
					}}>
					Are you sure you want to end the chat with this user?
				</DialogContent>
				<DialogActions
					sx={{
						borderTop: '1px solid #CBD9E6',
						background: '#F6F7F9',
					}}>
					<Button
						variant="outlined"
						sx={{ textTransform: 'capitalize' }}
						onClick={() => closeModal()}>
						Cancel
					</Button>
					<Button
						sx={{ textTransform: 'capitalize' }}
						variant="contained"
						onClick={() => endChat()}
						disabled={isModalLoading}>
						{isModalLoading ? <Loader size="small" /> : 'Confirm'}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default EndChatModal;
