/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import './Base.css';
import { Routes, Route, useNavigate, matchPath } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
	Badge,
	Button,
	Container,
	Divider,
	Drawer,
	ListItem,
	ListItemAvatar,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import _ from 'lodash';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleIcon from '@mui/icons-material/People';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ChatIcon from '@mui/icons-material/Chat';
import InsightsIcon from '@mui/icons-material/Insights';
import Color from 'color';
import ProtectedRoute from '../../route-guards/authorized-route';
import { Chat } from '../Chat/Chat';
import { Agents } from '../Agents/Agents';
import { USER_ROLES } from '../../enums/user-roles.enum';
import { StorageService } from '../../services/StorageService';
import { MessageTemplates } from '../MessageTemplates/MessageTemplates';
import { PushNotificationService } from '../../services/PushNotificationService';
import Analytics from '../Analytics/Analytics';
import { SfxService } from '../../services/SfxService';
import { Loader } from '../Loader/Loader';
import AccountDrawer from '../AccountDrawer/AccountDrawer';
import { Utils } from '../../services/Utils';

export const Base = () => {
	// Constants

	const navItems = [
		{
			display: 'Chats',
			icon: <ChatIcon />,
			path: './chats',
			roles: [USER_ROLES.AGENT],
			pathMatchString: '/:id/chats',
		},
		{
			display: 'Agents',
			icon: <PeopleIcon />,
			path: './agents',
			roles: [USER_ROLES.ADMIN],
			pathMatchString: '/:id/agents',
		},
		{
			display: 'Quick Replies',
			icon: <BookmarkIcon />,
			path: './quick-replies',
			roles: [USER_ROLES.ADMIN],
			pathMatchString: '/:id/quick-replies',
		},
		{
			display: 'Analytics',
			icon: <InsightsIcon />,
			path: './analytics',
			roles: [USER_ROLES.ADMIN],
			pathMatchString: '/:id/quick-replies',
		},
	];

	// State
	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [isAccountDrawerOpenInMobileView, setIsAccountDrawerOpenInMobileView] = useState(false);
	const [unreadMessageCountForDrawerMenu, setUnreadMessageCountForDrawerMenu] = useState(0);

	useEffect(() => {
		setIsLoading(true);
		PushNotificationService.setupBrowserNotification();
		document.documentElement.style.setProperty(
			'--brand-1',
			StorageService.authTenant.brandingConfig.theme.primary
				? StorageService.authTenant.brandingConfig.theme.primary
				: '#00AFF0',
		);
		document.documentElement.style.setProperty(
			'--brand-2',
			StorageService.authTenant.brandingConfig.theme.secondary
				? StorageService.authTenant.brandingConfig.theme.secondary
				: '#0AFF00',
		);
		document.documentElement.style.setProperty(
			'--brand-3',
			StorageService.authTenant.brandingConfig.theme.tertiary
				? StorageService.authTenant.brandingConfig.theme.tertiary
				: '#00AFF0',
		);
		setIsLoading(false);
	}, []);

	// Logic
	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const logout = () => {
		StorageService.clearAuthData();
		SfxService.stopWaitingUserSound();
		navigate('/login');
	};
	const handleDrawerToggle = () => {
		setIsAccountDrawerOpenInMobileView(!isAccountDrawerOpenInMobileView);
	};

	const updateUnreadCountForDrawerMenu = (count: number, isRead = false) => {
		setUnreadMessageCountForDrawerMenu((preCount) =>
			!isRead ? preCount + count : preCount - count,
		);
	};

	const filterByRole = (item: { display: string; path: string; roles: USER_ROLES[] }) =>
		StorageService.authUser?.role && item.roles.includes(StorageService.authUser?.role);

	// UI
	const renderMenu = () => (
		<Menu
			sx={{ mt: '45px', width: { xs: 300, md: 400 } }}
			id="menu-appbar"
			anchorEl={anchorElUser}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={Boolean(anchorElUser)}
			onClose={handleCloseUserMenu}>
			<ListItem alignItems="flex-start">
				<ListItemAvatar>
					<Avatar
						alt={`${StorageService.authUser?.firstName} ${StorageService.authUser?.lastName}`}>
						{StorageService.authUser?.firstName[0]}
						{StorageService.authUser?.lastName[0]}
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary={
						<Typography
							component="h4"
							color="text.primary"
							title={`${StorageService.authUser?.firstName} ${StorageService.authUser?.lastName}`}
							noWrap>
							{`${StorageService.authUser?.firstName} ${StorageService.authUser?.lastName}`}
						</Typography>
					}
					secondary={
						<>
							<Typography
								sx={{ display: 'block', fontWeight: 600 }}
								component="span"
								variant="body2"
								color="text.secondary"
								noWrap>
								{`${StorageService.authTenant?.name}  - ${_.capitalize(
									StorageService.authUser?.role,
								)}`}
							</Typography>
							<Typography
								sx={{ display: 'block' }}
								component="span"
								variant="body2"
								color="text.secondary"
								title={StorageService.authUser?.email}
								noWrap>
								{StorageService.authUser?.email}
							</Typography>
						</>
					}
				/>
			</ListItem>
			<Divider />
			<MenuItem onClick={logout}>
				<ListItemIcon>
					<LogoutIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>Log Out</ListItemText>
			</MenuItem>
		</Menu>
	);

	return (
		<>
			{isLoading && <Loader size="regular" />}
			{!isLoading && (
				<ThemeProvider
					theme={createTheme({
						palette: {
							primary: {
								main: Utils.getPrimaryColor(),
							},
							secondary: {
								main: Utils.getSecondaryColor(),
							},
						},
						utils: {
							dark: Color(Utils.getPrimaryColor()).darken(0.3).string(),
						},
					})}>
					<Container>
						<AppBar
							position="fixed"
							component="nav"
							sx={{
								background: 'white',
								color: '#4C4C4C',
								boxShadow: '0px 0px 10px rgba(183, 192, 206, 0.25)',
							}}>
							<Toolbar sx={{ justifyContent: { xs: 'space-between' } }}>
								<Badge
									sx={{
										'& .MuiBadge-badge': { backgroundColor: '#4FC766' },
										mr: 2,
										display: { xs: 'block', md: 'none' },
									}}
									color="primary"
									overlap="circular"
									badgeContent={
										isAccountDrawerOpenInMobileView ? '0' : unreadMessageCountForDrawerMenu
									}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right',
									}}>
									<IconButton
										color="inherit"
										aria-label="open drawer"
										edge="start"
										onClick={handleDrawerToggle}>
										<MenuIcon />
									</IconButton>
								</Badge>

								<Typography
									variant="h6"
									component="div"
									sx={{
										flexGrow: 1,
										fontWeight: 600,
										textAlign: { xs: 'center', md: 'left' },
									}}>
									{StorageService.authTenant.name}{' '}
									{Utils.capitalize(StorageService.authUser.role?.toLowerCase())} Dashboard
								</Typography>
								<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}>
									{navItems.filter(filterByRole).map((item) => (
										<Button
											key={item.path}
											sx={{
												color: matchPath(item.pathMatchString, window.location.pathname)
													? Utils.getPrimaryColor()
													: '#4C4C4C',
												pl: 4,
												pr: 4,
												textTransform: 'capitalize',
												fontSize: 'meduim',
												'& .MuiButton-root': {
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'center',
												},
											}}
											startIcon={item.icon}
											href={item.path}>
											{item.display}
										</Button>
									))}
								</Box>
								<Drawer
									variant="permanent"
									anchor="bottom"
									sx={{
										display: { xs: 'block', md: 'none' },
										'& .MuiDrawer-paper': {
											height: '72px',
											flexDirection: 'row',
											justifyContent: 'space-evenly',
										},
									}}>
									{navItems.filter(filterByRole).map((item) => (
										<Button
											key={item.path}
											sx={{
												color:
													// eslint-disable-next-line no-nested-ternary
													window.location.pathname === item.path
														? Utils.getPrimaryColor()
														: '#4C4C4C',
												pl: 4,
												pr: 4,
												textTransform: 'capitalize',
												fontSize: 'medium',
											}}
											startIcon={item.icon}
											href={item.path}>
											{item.display}
										</Button>
									))}
								</Drawer>
								<Tooltip title="View user" sx={{ flexGrow: 1 }}>
									<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
										<Avatar alt={StorageService.authUser?.firstName}>
											{StorageService.authUser?.firstName[0]}
											{StorageService.authUser?.lastName[0]}
										</Avatar>
									</IconButton>
								</Tooltip>
								{renderMenu()}
							</Toolbar>
						</AppBar>

						<Box component="nav">
							<AccountDrawer
								handleDrawer={handleDrawerToggle}
								updateUnreadCountOnDrawerMenu={updateUnreadCountForDrawerMenu}
								accounts={StorageService.allTenantConfigs}
								mobileOpen={isAccountDrawerOpenInMobileView}
							/>
						</Box>
						<Box
							component="main"
							sx={{
								pt: { xs: 10, sm: 12 },
								pb: { xs: 3, sm: 5 },
								width: { xs: '100%', sm: '100%', md: '85%' },
								position: { md: 'absolute' },
								left: '10%',
							}}>
							<Box
								sx={{
									background: 'white',
									boxShadow: '0px 0px 10px rgba(183, 192, 206, 0.25)',
									// p: 3,
									pb: 0,
									borderRadius: 1,
									position: { xs: 'relative' },
								}}>
								<Routes>
									<Route
										path="/chats"
										element={
											<ProtectedRoute role={USER_ROLES.AGENT}>
												<Chat />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/agents"
										element={
											<ProtectedRoute role={USER_ROLES.ADMIN}>
												<Agents />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/quick-replies"
										element={
											<ProtectedRoute role={USER_ROLES.ADMIN}>
												<MessageTemplates />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/analytics"
										element={
											<ProtectedRoute role={USER_ROLES.ADMIN}>
												<Analytics />
											</ProtectedRoute>
										}
									/>
									<Route path="" element={<ProtectedRoute defaultComponent />} />
									<Route path="*" element={<ProtectedRoute defaultComponent />} />
								</Routes>
							</Box>
						</Box>
					</Container>
				</ThemeProvider>
			)}
		</>
	);
};
