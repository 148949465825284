/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { Icon } from '@isc/chatui-core';
import './Loader.css';

export const Loader = (props: { size: 'regular' | 'small' } = { size: 'regular' }): JSX.Element => (
	<Icon
		type="spinner"
		spin
		className="spinner"
		fontSize={props.size === 'regular' ? 'xxx-large' : 'large'}
	/>
);
