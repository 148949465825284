/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { RichText, Button } from '@isc/chatui-core';

import {
	ParsedMessage,
	MESSAGE_USER_TYPE,
	MessageContentText,
	MessageContentCard,
	MessageContentCarousel,
} from '../../types';

import { Loader } from '../Loader/Loader';
import { Text } from '../Text/Text';
import { Card } from '../Card/Card';
import { Carousel } from '../Carousel/Carousel';

type FetchPastHistoryFunction = (message: ParsedMessage) => void;

const getMessageWithUserName = (
	user: { imageUrl?: string; name?: string },
	position: 'left' | 'right' | 'center' | undefined,
) => (position === 'left' ? `${user.name} Said` : 'You Said');

const renderScreenReaderContent = (messageWithUserName: string) => (
	<span className="sr-only">{messageWithUserName}</span>
);

const renderSystemMessage = (content: MessageContentText) => (
	<div className="system-message-container">
		<RichText content={content.text} className="system-message" />
	</div>
);

const renderTextMessage = (
	user: { imageUrl?: string; name?: string },
	content: MessageContentText,
	position: 'left' | 'right' | 'center' | undefined,
	messageSender: ParsedMessage['user'],
	dateAndTime: ParsedMessage['dateAndTime'],
) => {
	const messageWithUserName = getMessageWithUserName(user, position);
	return (
		<>
			{renderScreenReaderContent(messageWithUserName)}
			<Text content={content} position={position} user={messageSender} dateAndTime={dateAndTime} />
		</>
	);
};

const renderCardMessage = (
	user: { imageUrl?: string; name?: string },
	content: MessageContentCard,
	position: 'left' | 'right' | 'center' | undefined,
	messageSender: ParsedMessage['user'],
	dateAndTime: ParsedMessage['dateAndTime'],
) => {
	const messageWithUserName = getMessageWithUserName(user, position);
	return (
		<>
			{renderScreenReaderContent(messageWithUserName)}
			<Card
				dateAndTime={dateAndTime}
				user={messageSender}
				content={content}
				size="xl"
				isCarousel={false}
			/>
		</>
	);
};

const renderCarouselMessage = (
	user: { imageUrl?: string; name?: string },
	content: MessageContentCarousel,
	messageSender: ParsedMessage['user'],
	position: 'left' | 'right' | 'center' | undefined,
	dateAndTime: ParsedMessage['dateAndTime'],
) => {
	const messageWithUserName = getMessageWithUserName(user, position);
	return (
		<>
			{renderScreenReaderContent(messageWithUserName)}
			<Carousel content={content} dateAndTime={dateAndTime} user={messageSender} />
		</>
	);
};

const renderFirstMessage = (
	isLoading: boolean,
	isLastMessageFetched: boolean,
	isLocalstorageBlocked: boolean,
	fetchPastHistory: FetchPastHistoryFunction,
	allMessages: ParsedMessage[],
) => (
	<div className="chat-window-load-more-button">
		{isLoading ? (
			<Button>
				<Loader size="small" />
			</Button>
		) : null}
		{!isLoading && !isLastMessageFetched && !isLocalstorageBlocked ? (
			// eslint-disable-next-line max-len
			<Button onClick={() => fetchPastHistory(allMessages[1] as ParsedMessage)}>Show more</Button>
		) : null}
	</div>
);

export const renderMessageContent = (
	user: { imageUrl?: string; name?: string },
	msg: ParsedMessage,
	allowPagination: boolean,
	isLoading: boolean,
	isLastMessageFetched: boolean,
	isLocalstorageBlocked: boolean,
	fetchPastHistory: FetchPastHistoryFunction,
	allMessages: ParsedMessage[],
) => {
	let messageContent = null;
	const { type, content, position, user: messageSender, dateAndTime } = msg;

	if (messageSender.type === MESSAGE_USER_TYPE.SYSTEM && type === 'text') {
		const content2 = msg.content as MessageContentText;
		renderSystemMessage(content2);
	}
	switch (type) {
		case 'text': {
			const textContent = content as MessageContentText;
			messageContent = renderTextMessage(
				user,
				textContent as MessageContentText,
				position,
				messageSender,
				dateAndTime,
			);

			break;
		}
		case 'card': {
			const cardContent = content as MessageContentCard;
			messageContent = renderCardMessage(
				user,
				cardContent as MessageContentCard,
				position,
				messageSender,
				dateAndTime,
			);
			break;
		}
		case 'carousel': {
			const carouselContent = content as MessageContentCarousel;
			messageContent = renderCarouselMessage(
				user,
				carouselContent as MessageContentCarousel,
				messageSender,
				position,
				dateAndTime,
			);
			break;
		}
		case 'first': {
			if (allowPagination) {
				messageContent = renderFirstMessage(
					isLoading,
					isLastMessageFetched,
					isLocalstorageBlocked,
					fetchPastHistory,
					allMessages,
				);
			}

			break;
		}
		default: {
			messageContent = null;
			break;
		}
	}
	return messageContent;
};
