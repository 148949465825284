/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { ScrollView } from '@isc/chatui-core';
import { MessageContentCard, MessageContentCarousel, ParsedMessage } from '../../types';
import { Card } from '../Card/Card';

interface CarouselProps {
	content: MessageContentCarousel;
	user: ParsedMessage['user'];
	dateAndTime: ParsedMessage['dateAndTime'];
}

interface CardProps {
	content: MessageContentCard;
	user?: ParsedMessage['user'] | null;
	dateAndTime?: ParsedMessage['dateAndTime'] | null;
}

const renderCard = ({ content, user, dateAndTime }: CardProps) => (
	<Card content={content} size="lg" user={user} dateAndTime={dateAndTime} isCarousel />
);

export const Carousel = ({ content, user, dateAndTime }: CarouselProps): JSX.Element => {
	const data = content.cards.map((card) => ({ content: card, user, dateAndTime }));
	return <ScrollView data={data} renderItem={renderCard} />;
};
