/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
export enum Applications {
	CHATBOT = 'CHATBOT', // DialogFlow API having quick replies & cards
	EXTERNAL_CHAT = 'EXTERNAL_CHAT',
	LIVE_AGENT_DASHBOARD = 'LIVE_AGENT_DASHBOARD',
	CHATBOT_GPT = 'CHATBOT_GPT', // GPT API having text stream
	CHATBOT_GPT_V2 = 'CHATBOT_GPT_V2', // GPT API having quickreplies & cards
}
