/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import {
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	DialogActions,
	Button,
	Box,
	TextField,
	Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { MESSAGES } from '../../../messages';
import { Loader } from '../../Loader/Loader';
import { ToastProperties } from '../../../types';
import Toast from '../../Toast/Toast';
import { ApiService } from '../../../services/ApiService';

interface props {
	open: boolean;
	onClose: (success?: boolean) => void;
}
const AddMessageTemplateModal: FC<props> = ({ open, onClose }: props) => {
	const {
		control: addMessageTemplateControls,
		handleSubmit: addMessageTemplateSubmit,
		formState: { errors: addMessageTemplateErrors },
		reset: resetAddMessageTemplateForm,
	} = useForm<{ title: string; template: string }>({
		defaultValues: {
			title: '',
			template: '',
		},
	});

	const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
	const [toastProperties, setToastProperties] = useState<ToastProperties>({
		open: false,
		isError: false,
		message: '',
	});

	const onFormSubmit = addMessageTemplateSubmit(async (data) => {
		setIsModalLoading(true);
		try {
			const response = await ApiService.addMessageTemplate(data);
			setToastProperties({ open: true, message: response.data.message, isError: false });
			onClose(true);
			resetAddMessageTemplateForm(undefined);
		} catch (error) {
			const typedError = error as AxiosError;
			setToastProperties({
				open: true,
				message: typedError.response?.data.message || MESSAGES.GENERIC_ERROR,
				isError: true,
			});
		} finally {
			setIsModalLoading(false);
		}
	});

	const handleClose = (success = false) => {
		resetAddMessageTemplateForm();
		onClose(success);
	};
	return (
		<>
			<Dialog open={open} onClose={() => handleClose()}>
				<form onSubmit={onFormSubmit}>
					<DialogTitle
						sx={{
							borderBottom: '1px solid #CBD9E6',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}>
						New Template
						<IconButton onClick={() => handleClose()}>
							<CloseIcon />
						</IconButton>
					</DialogTitle>
					<DialogContent
						sx={{
							background: '#F9FAFB',
						}}>
						<Box sx={{ display: 'flex', flexDirection: 'column', pt: { sm: 2 } }}>
							<Controller
								name="title"
								control={addMessageTemplateControls}
								rules={{ required: true, maxLength: 20 }}
								render={({ field }) => (
									<TextField
										margin="normal"
										required
										fullWidth
										id="title"
										label="Title"
										name="title"
										autoFocus
										value={field.value}
										onChange={(em) => field.onChange(em.target.value)}
										error={!!addMessageTemplateErrors.title?.type || false}
										sx={{ mr: { sm: 2 } }}
										helperText={
											(addMessageTemplateErrors.title?.type === 'required' &&
												'Title is required') ||
											(addMessageTemplateErrors.title?.type === 'maxLength' && MESSAGES.MAX_TITLE)
										}
									/>
								)}
							/>
							<Controller
								name="template"
								control={addMessageTemplateControls}
								rules={{ required: true, maxLength: 2048 }}
								render={({ field }) => (
									<TextField
										margin="normal"
										required
										fullWidth
										id="template"
										label="Template"
										name="template"
										multiline
										minRows={4}
										value={field.value}
										onChange={(em) => field.onChange(em.target.value)}
										error={!!addMessageTemplateErrors.template?.type || false}
										sx={{ mr: { sm: 2 } }}
										helperText={
											(addMessageTemplateErrors.template?.type === 'required' &&
												'Template is required') ||
											(addMessageTemplateErrors.template?.type === 'maxLength' &&
												MESSAGES.MAX_TEMPLATE)
										}
									/>
								)}
							/>
						</Box>
						<Typography
							variant="subtitle1"
							sx={{ color: '#8189A9', pt: { xs: 1, sm: 3 }, pb: { sm: 1 } }}>
							Note: Add %name% to automatically be replaced by the name of the person you are
							replying to
						</Typography>
					</DialogContent>
					<DialogActions
						sx={{
							borderTop: '1px solid #CBD9E6',
							background: '#F6F7F9',
						}}>
						<Button
							sx={{ textTransform: 'capitalize' }}
							variant="outlined"
							onClick={() => handleClose()}>
							Cancel
						</Button>
						<Button
							sx={{ textTransform: 'capitalize' }}
							variant="contained"
							type="submit"
							disabled={isModalLoading}>
							{isModalLoading ? <Loader size="small" /> : 'Save'}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
			<Toast
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...toastProperties}
				onClose={() => setToastProperties({ ...toastProperties, open: false })}
			/>
		</>
	);
};

export default AddMessageTemplateModal;
