/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { Alert, Box, Button, TableSortLabel, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import { ApiService } from '../../services/ApiService';
import { ParsedUser, ToastProperties } from '../../types';
import Toast from '../Toast/Toast';
import { MESSAGES } from '../../messages';
import { Loader } from '../Loader/Loader';
import { STATUS } from '../../enums/status.enum';
import { AgentsAddModal } from './modals/Agents-add';
import { AgentsEditModal } from './modals/Agents-edit';
import { IscChip } from '../IscChip/IscChip';
import useDebounce from '../../hooks/useDebounce';
import { SORT_DIRECTION } from '../../enums/sort-direction.enum';
import Searchbox from '../searchbox/Searchbox';
import { SORT_KEY } from '../../enums/sort-key.enum';
import StatusChangeConfirmationModal from './modals/Status-change-confirmation';
import { USER_ROLES } from '../../enums/user-roles.enum';

export const Agents = () => {
	// States
	const [toastProperties, setToastProperties] = useState<ToastProperties>({
		open: false,
		isError: false,
		message: '',
	});
	const [isLoading, setisLoading] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [rows, setRows] = useState<ParsedUser[]>();
	const [total, setTotal] = useState<number>(0);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [modalData, setModalData] = useState({ id: '', status: STATUS.ACTIVE });
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

	const openMenu = Boolean(anchorEl);
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleMenuClick = (_id: string, event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const [editModal, setEditModal] = useState(false);
	const [agentsModal, setAgentsModal] = useState(false);
	const toggleaAgentsModal = () => {
		setAgentsModal(!agentsModal);
		if (anchorEl) {
			setAnchorEl(null);
		}
	};

	const [sortParams, setSortParams] = useState({
		key: SORT_KEY.NAME,
		direction: SORT_DIRECTION.ASC,
	});
	const [searchData, setSearchData] = useState<string | null>(null);
	const debouncedSearchText = useDebounce(searchData, 1000);
	const [editModalData, setEditModalData] = useState({ _id: '', firstName: '', lastName: '' });

	// Handlers

	const fetchAgents = async (data?: {
		sortKey?: string;
		sortDirection?: string;
		searchText?: string | null;
		page?: number;
	}) => {
		try {
			setisLoading(true);
			const response = await ApiService.fetchUsers({
				...data,
				searchText: data?.searchText || debouncedSearchText,
				page: data && data.page !== undefined ? data.page + 1 : page + 1,
				role: USER_ROLES.AGENT,
			});
			setRows(response.data);
			setTotal(response.meta?.total);
		} catch (error) {
			setToastProperties({ open: true, message: MESSAGES.GENERIC_ERROR, isError: true });
		} finally {
			setisLoading(false);
		}
	};

	const handleChangePage = (_data: unknown, newPage: number) => {
		setPage(newPage);
		fetchAgents({
			page: newPage,
			sortKey: sortParams.key,
			sortDirection: sortParams.direction,
		});

		// The page number is being sent to fetchAgents directly because the state might not have the latest page number. Set state is not synchronous.
		// https://github.com/facebook/react/issues/16858
	};

	const onSearch = (search: string | null) => {
		setSearchData(search);
	};

	const sortHandler = (sortKey: SORT_KEY) => {
		setPage(0);
		let direction = SORT_DIRECTION.ASC;
		if (sortParams.key === sortKey) {
			direction =
				sortParams.direction === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC;
		}
		setSortParams({ key: sortKey, direction });
	};

	// Init
	useEffect(() => {
		fetchAgents({ sortKey: sortParams.key, sortDirection: sortParams.direction });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setPage(0);
		if (debouncedSearchText) {
			fetchAgents({ searchText: debouncedSearchText, page: 0 });
		} else {
			fetchAgents();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchText]);

	useEffect(() => {
		fetchAgents({
			sortKey: sortParams.key,
			sortDirection: sortParams.direction,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortParams]);
	const closeConfirmationModal = (success: boolean) => {
		handleClose();
		setOpenConfirmationModal(false);
		if (success) {
			fetchAgents();
		}
	};

	// UI
	const renderTable = () => (
		<TableContainer
			component={Paper}
			className="agent-table"
			sx={{ boxShadow: 'none', border: '0px', mt: '10px' }}>
			<Table sx={{ width: '100%' }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell align="left" sx={{ minWidth: 170, fontWeight: 600 }}>
							Name
							<TableSortLabel
								active={sortParams.key === SORT_KEY.NAME}
								direction={
									sortParams.key === SORT_KEY.NAME ? sortParams.direction : SORT_DIRECTION.ASC
								}
								onClick={() => {
									sortHandler(SORT_KEY.NAME);
								}}
							/>
						</TableCell>
						<TableCell align="left" sx={{ minWidth: 170, fontWeight: 600 }}>
							Status
							<TableSortLabel
								active={sortParams.key === SORT_KEY.STATUS}
								direction={
									sortParams.key === SORT_KEY.STATUS ? sortParams.direction : SORT_DIRECTION.ASC
								}
								onClick={() => {
									sortHandler(SORT_KEY.STATUS);
								}}
							/>
						</TableCell>
						<TableCell align="left" sx={{ minWidth: 170, fontWeight: 600 }}>
							Action
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows &&
						rows.map((row) => (
							<TableRow key={row.email}>
								<TableCell align="left" colSpan={1}>
									{row.firstName} {row.lastName} <br />{' '}
									<span className="text-muted"> {row.email} </span>
								</TableCell>
								<TableCell align="left">
									<IscChip status={row.status as STATUS} />
								</TableCell>
								<TableCell align="left">
									<Button
										variant="outlined"
										sx={{
											color: '#2C405A',
											textTransform: 'capitalize',
											borderColor: '#E4E8EC',
											pr: 1,
											pl: 2,
										}}
										data-status={row.status}
										data-id={row._id}
										id="basic-button"
										aria-controls={openMenu ? 'basic-menu' : undefined}
										aria-haspopup="true"
										aria-expanded={openMenu ? 'true' : undefined}
										onClick={(event) => {
											handleMenuClick(row._id, event);
										}}>
										Edit
										<ArrowDropDownIcon sx={{ ml: 1 }} />
									</Button>
								</TableCell>
							</TableRow>
						))}
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[]}
							colSpan={10}
							count={total}
							rowsPerPage={10}
							page={page}
							onPageChange={handleChangePage}
							sx={{ border: 'none' }}
						/>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
	const renderMenu = () => (
		<Menu
			id="basic-menu"
			anchorEl={anchorEl}
			open={openMenu}
			onClick={handleClose}
			MenuListProps={{
				'aria-labelledby': 'basic-button',
			}}>
			{anchorEl?.dataset.status === STATUS.ACTIVE && (
				<MenuItem
					onClick={() => {
						const agent = rows?.find((r) => r._id === (anchorEl.dataset.id as string));
						setEditModalData({
							_id: agent?._id as string,
							firstName: agent?.firstName as string,
							lastName: agent?.lastName as string,
						});
						setEditModal(true);
					}}>
					Edit
				</MenuItem>
			)}
			{anchorEl?.dataset.status === STATUS.ACTIVE && (
				<MenuItem
					onClick={() => {
						setModalData({
							id: anchorEl.dataset.id as string,
							status: anchorEl.dataset.status as STATUS,
						});
						setOpenConfirmationModal(true);
					}}>
					Disable
				</MenuItem>
			)}
			{anchorEl?.dataset.status === STATUS.DISABLED && (
				<MenuItem
					onClick={() => {
						setModalData({
							id: anchorEl.dataset.id as string,
							status: anchorEl.dataset.status as STATUS,
						});
						setOpenConfirmationModal(true);
					}}>
					Enable
				</MenuItem>
			)}
		</Menu>
	);

	// Main
	return (
		<Box sx={{ p: 3, pb: 1 }}>
			<Box
				sx={{
					display: 'flex',
					direction: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					p: 1,
				}}>
				<Typography typography="h6">Agents</Typography>
				<Button
					variant="contained"
					onClick={toggleaAgentsModal}
					sx={{ textTransform: 'capitalize' }}>
					<AddIcon /> &nbsp;New Agent
				</Button>
			</Box>
			<Searchbox searchData={searchData} setSearchData={onSearch} placeholder="Search Agents" />
			{isLoading && (
				<Box
					sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pt: 10, pb: 15 }}>
					<Loader size="regular" />
				</Box>
			)}
			{!isLoading && !rows?.length && (
				<Alert severity="warning" sx={{ mt: 10, mb: 10 }}>
					No Agents found
				</Alert>
			)}
			{!isLoading && rows?.length !== 0 && renderTable()}
			{renderMenu()}
			<AgentsEditModal
				open={editModal}
				data={editModalData}
				onClose={(value) => {
					setEditModal(false);
					if (value) {
						fetchAgents();
					}
				}}
			/>
			<AgentsAddModal
				open={agentsModal}
				onClose={(value) => {
					setAgentsModal(false);
					if (value) {
						fetchAgents();
					}
				}}
			/>
			<Toast
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...toastProperties}
				onClose={() => setToastProperties({ ...toastProperties, open: false })}
			/>
			<StatusChangeConfirmationModal
				open={openConfirmationModal}
				onClose={closeConfirmationModal}
				modalData={modalData}
			/>
		</Box>
	);
};
