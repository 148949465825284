/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { MESSAGE_USER_TYPE } from '../types';

const CDN_LINK = process.env.REACT_APP_CDN_URL;
const longPopSound = new Audio(`${CDN_LINK}/audio/long-pop.mp3`);
const shortPopSound = new Audio(`${CDN_LINK}/audio/short-pop.mp3`);
const waitingGuestsSound = new Audio(`${CDN_LINK}/audio/prolong-notification.wav`);

const playSound = (type: MESSAGE_USER_TYPE): void => {
	let sound: HTMLAudioElement;
	switch (type) {
		case MESSAGE_USER_TYPE.GUEST:
			sound = shortPopSound;
			break;
		case MESSAGE_USER_TYPE.BOT:
		case MESSAGE_USER_TYPE.AGENT:
		case MESSAGE_USER_TYPE.SYSTEM:
			sound = longPopSound;
			break;
		default:
			sound = longPopSound;
			break;
	}
	setTimeout(() => {
		/**
		 * The error is usually about the DOM not being touched by the user. Nothing to handle here.
		 * Not catching throws an ugly error
		 */
		sound.play().catch(() => null);
	}, 0);
};

const playWaitingUserSound = () => {
	waitingGuestsSound.play().catch(() => null);
	waitingGuestsSound.loop = true;
};
const stopWaitingUserSound = () => {
	waitingGuestsSound.pause();
	waitingGuestsSound.loop = false;
};
// eslint-disable-next-line import/prefer-default-export
export const SfxService = {
	playSound,
	playWaitingUserSound,
	stopWaitingUserSound,
};
