/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import './Footer.css';

const logo = 'https://cdn.ischoolconnect.com/common-files/iSchoolConnect-logo.svg';

export const Footer = (): JSX.Element => (
	<footer>
		Powered by &nbsp;
		<img alt="ISchoolConnect logo" className="logo" src={logo} />
	</footer>
);
