/* eslint-disable no-console */
/* eslint-disable padded-blocks */
/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const logError = (
	error: Error | unknown,
	message = 'Something went wrong. Please inform the developer: ',
): void => {
	if (process.env.NODE_ENV !== 'production') {
		console.error(message);
		console.error(error);
		// Add logging to our server, if possible
	}
};

export const LoggerService = {
	logError,
};
