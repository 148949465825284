/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
export enum SORT_KEY {
	NAME = 'name',
	STATUS = 'status',
	TITLE = 'title',
}
