/* eslint-disable max-len */
/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { Icon, Text } from '@isc/chatui-core';
import '@isc/chatui-core/es/styles/index.less';
import '@isc/chatui-core/dist/index.css';
import './Header.css';
import '../../isc-icons.css';
import Avatar from 'react-avatar';
import { FC, useEffect, useState } from 'react';
import { Box, Button, Menu, MenuItem, Tooltip } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import { PushNotificationService } from '../../services/PushNotificationService';
import { Utils } from '../../services/Utils';
import { UIService } from '../../services/UIService';
import { DEVICE_TYPE, ParsedUser } from '../../types';
import { ApiService } from '../../services/ApiService';
import { USER_ROLES } from '../../enums/user-roles.enum';
import { STATUS } from '../../enums/status.enum';
import { LoggerService } from '../../services/LoggerService';
import { Loader } from '../Loader/Loader';

interface props {
	user?: { imageUrl?: string; name?: string } | null;
	actionsHandler?: ((action: string) => void) | null;
	allowClose?: boolean;
	allowResize?: boolean;
	allowPrevious?: boolean;
	boldTitle?: boolean;
	allowEndChat?: boolean;
	allowChatTransfer?: boolean;
	endChatHandler?: (() => void) | null;
	transferChatHandler?: ((userId: string) => void) | null;
	disableEndChat?: boolean;
	disableTransferChat?: boolean;
}

const Header: FC<props> = ({
	user,
	actionsHandler,
	allowClose,
	allowResize,
	allowPrevious,
	boldTitle,
	allowEndChat,
	endChatHandler,
	allowChatTransfer,
	transferChatHandler,
	disableEndChat,
	disableTransferChat,
}: props): JSX.Element => {
	const [isFrameExpanded, setIsFrameExpanded] = useState(Utils.isFrameExpanded());
	const [deviceType, setDeviceType] = useState(Utils.getDeviceType());
	const [anchorForTransferMenu, setAnchorForTransferMenu] = useState<HTMLElement | null>();
	const [pageForTransferUser, setPageforTransferUsers] = useState<number>(0);
	const [tranferUsersList, setTransferUsersList] = useState<ParsedUser[]>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const transferUserMenu = Boolean(anchorForTransferMenu);

	/** ******************************************** Helper functions *************************************************** */

	const fetchTransferUsers = async (directPage?: number) => {
		try {
			setIsLoading(true);
			const response = await ApiService.fetchUsers({
				page: directPage ? directPage + 1 : pageForTransferUser + 1,
				limit: 5,
				role: USER_ROLES.AGENT,
				status: STATUS.ACTIVE,
			});
			if (pageForTransferUser > 0) {
				setTransferUsersList((existingUsers) => existingUsers?.concat(response.data));
			} else {
				setTransferUsersList(response.data);
			}
		} catch (error) {
			LoggerService.logError(error, 'failed to load for transfer agents dropdown list');
		} finally {
			setIsLoading(false);
		}
	};

	const openTransferUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorForTransferMenu(event.currentTarget);
	};

	const closeTransferMenu = () => {
		setAnchorForTransferMenu(null);
	};

	const closeChatWindow = () => {
		UIService.toggleChatWindow();
		if (actionsHandler) {
			actionsHandler('CLOSE_WIDGET');
		}
	};

	const handleResize = () => PushNotificationService.sendExpansionNotification();

	/** ******************************************** UseEffects *************************************************** */

	useEffect(() => {
		const resizeHandler = () => {
			setDeviceType(Utils.getDeviceType());
			setIsFrameExpanded(Utils.isFrameExpanded());
		};
		const transferUserInitialSetup = async () => {
			setPageforTransferUsers(0);
			setTransferUsersList([]);
			fetchTransferUsers();
		};
		window.addEventListener('resize', resizeHandler);
		if (allowChatTransfer) {
			transferUserInitialSetup();
		}
		return () => window.removeEventListener('resize', resizeHandler);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		if (allowChatTransfer) {
			setPageforTransferUsers((newPage) => {
				fetchTransferUsers(newPage);
				return newPage;
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageForTransferUser]);

	/** ******************************************** UI Helpers *************************************************** */

	const userDetailsTitle = (
		<div className="title-container">
			{allowPrevious && (
				<Icon
					onClick={() => {
						closeChatWindow();
					}}
					className="hide-on-expanded-frame header-icon chevron"
					type="chevron-left"
				/>
			)}
			{user?.name && (
				<>
					<Avatar
						className="name-avatar"
						src={user?.imageUrl}
						round
						size="40"
						name={user?.name}
						title=""
						maxInitials={3}
					/>
					<Text className={boldTitle ? 'chat-title' : 'title'}>
						{Utils.getTrimmedText(user?.name, 15)}
					</Text>
				</>
			)}
		</div>
	);

	const generalTitle = (
		<div className="title-container">
			<Text className="title">Chats</Text>
		</div>
	);

	const resizeButtons = (
		<i
			tabIndex={0}
			onClick={() => handleResize()}
			className={`header-icon  ${isFrameExpanded ? 'isc-collapse-alt' : 'isc-expand-alt'}  ${
				deviceType === DEVICE_TYPE.MOBILE ? 'hide' : ''
			}`}
			onKeyUp={(event) => {
				if (event.key === 'Enter') handleResize();
			}}
			aria-label="resize"
			role="button"
		/>
	);

	const closeButton = (
		<i
			tabIndex={0}
			onClick={() => PushNotificationService.sendCloseNotification({ mouseClick: true })}
			onKeyUp={(event) => {
				if (event.key === 'Enter')
					PushNotificationService.sendCloseNotification({ mouseClick: true });
			}}
			className="isc-times-solid header-icon"
			aria-label="close"
			role="button"
		/>
	);

	const transferAgentMenuAndButton = (
		<>
			<Tooltip title="Tranfer to another agent. Please make sure the agent is online.">
				<Button
					sx={{ ml: 1, textTransform: 'capitalize' }}
					size="small"
					id="transfer-chat-button"
					onClick={openTransferUserMenu}
					disabled={disableTransferChat}
					variant="contained"
					startIcon={<GroupIcon />}>
					Transfer
				</Button>
			</Tooltip>
			<Menu
				id="basic-menu"
				anchorEl={anchorForTransferMenu}
				open={transferUserMenu}
				onClose={closeTransferMenu}
				sx={{ height: 500 }}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}>
				{!tranferUsersList?.length && <MenuItem> No users </MenuItem>}
				{tranferUsersList?.length && (
					<>
						{tranferUsersList.map((agent) => (
							<MenuItem
								onClick={() => {
									if (transferChatHandler) transferChatHandler(agent._id);
									closeTransferMenu();
								}}>
								{agent.firstName} {agent.lastName}
							</MenuItem>
						))}
						<MenuItem
							sx={{ justifyContent: 'center' }}
							onClick={() => setPageforTransferUsers((currentPage) => currentPage + 1)}>
							{isLoading ? <Loader size="small" /> : 'Show More'}
						</MenuItem>
					</>
				)}
			</Menu>
		</>
	);

	const EndChatButton = (
		<Tooltip title="End the conversation">
			<Button
				sx={{ ml: 1, textTransform: 'capitalize' }}
				size="small"
				id="end-chat-button"
				tabIndex={0}
				variant="contained"
				disabled={disableEndChat}
				onClick={() => {
					if (endChatHandler) endChatHandler();
				}}>
				End
			</Button>
		</Tooltip>
	);
	return (
		<Box className="header-container">
			<Box className="title-container">{user ? userDetailsTitle : generalTitle}</Box>
			<Box sx={{ display: user ? 'flex' : { sm: 'flex', md: 'none' }, flexDirection: 'row' }}>
				{allowChatTransfer && user && transferAgentMenuAndButton}
				{allowEndChat && user && EndChatButton}
				{allowResize && resizeButtons}
				{allowClose && closeButton}
			</Box>
		</Box>
	);
};

Header.defaultProps = {
	user: null,
	actionsHandler: null,
	allowClose: false,
	allowResize: false,
	allowPrevious: false,
	boldTitle: false,
	allowEndChat: false,
	endChatHandler: null,
	allowChatTransfer: false,
	transferChatHandler: null,
	disableEndChat: false,
	disableTransferChat: false,
};

export default Header;
