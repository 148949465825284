/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */ // TODO: Have a look at this rule later
/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import Chat, { useMessages, RecorderHandle, ToolbarItemProps } from '@isc/chatui-core';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import { Subscription } from 'rxjs';
import { MessageId } from '@isc/chatui-core/lib/components/Message';
import {
	ParsedMessage,
	MESSAGE_USER_TYPE,
	ParsedRoom,
	QuickReply,
	GenericError,
	ToastProperties,
} from '../../types';
import { LoggerService } from '../../services/LoggerService';
import { SfxService } from '../../services/SfxService';
import { StorageService } from '../../services/StorageService';
import Header from '../Header/Header';
import { Fallback, FallbackType } from '../Fallback/Fallback';
import { Footer } from '../Footer/Footer';
import { ChatService, MessageEventsFromWidget } from '../../services/ChatService';
import { useKeyboardShortcuts } from '../../hooks/useKeyboardShortcuts';
import '@isc/chatui-core/es/styles/index.less';
import '@isc/chatui-core/dist/index.css';
import './ChatWindow.css';
import { Utils } from '../../services/Utils';
import { MESSAGES } from '../../messages';
import { UIService } from '../../services/UIService';
import Toast from '../Toast/Toast';
import { ApiService } from '../../services/ApiService';
import MessageTemplatesPopover from '../MessageTemplates/Message-template-select';
import { STATUS } from '../../enums/status.enum';
import { mainMenuQuickReply } from '../../constants';
import EndChatModal from './modals/End-chat';
import msgtemplates from '../../assets/icons/edit.png';
import downloadChats from '../../assets/icons/downloadChat.png';
import { renderMessageContent } from './RenderMessageContent';

const messageIds: MessageId[] = [];

const firstMessage: ParsedMessage = {
	_id: '0',
	position: 'left',
	content: {
		text: 'Load More',
	},
	user: {
		type: MESSAGE_USER_TYPE.SYSTEM,
	},
	type: 'first',
};

const blockedStorageMessage: ParsedMessage = {
	_id: Utils.unique(),
	type: 'card',
	position: 'left',
	user: {
		type: MESSAGE_USER_TYPE.AGENT,
	},
	content: {
		subTitle: MESSAGES.NO_LOCAL_STORAGE_ACCESS,
		actions: [
			{
				display: 'View',
				// eslint-disable-next-line max-len
				link: 'https://medium.com/@kgoyal_99360/how-to-enable-local-storage-9e49621acec6',
			},
		],
	},
};

interface props {
	room?: ParsedRoom | null; // room to be rendered
	handleActionsOnHeader?: ((action: string) => void) | null; // handler for header actions TODO: refactor this entire flow
	allowEndChat?: boolean; // Live Agent end Chat
	allowClose?: boolean; // for header close
	allowResize?: boolean; // resize allowed
	allowPrevious?: boolean;
	allowQuickReplies?: boolean; // quick replies are only allowed for chatbot
	fetchTitleAndAvatarFromStorage?: boolean; // for chatbot
	addListenerForBotFirstMessage?: boolean; // for chatbot
	solo?: boolean; // if chatwindow is coupled with chatlist
	allowPagination?: boolean; // pagination is not allowed for chatbot
	renderWithEmptyRoom?: boolean; // with chatbot, we do not pass any room
	allowIscBranding?: boolean; // this is not allowed for some applications
	boldTitle?: boolean;
	allowToasts?: boolean;
	allowMessageTemplates?: boolean;
	allowDownloadChat?: boolean;
	allowChatTransfer?: boolean;
}

const ChatWindow: FC<props> = ({
	room = null,
	handleActionsOnHeader,
	allowQuickReplies,
	fetchTitleAndAvatarFromStorage,
	addListenerForBotFirstMessage,
	solo,
	allowPagination,
	renderWithEmptyRoom,
	allowIscBranding,
	allowClose,
	allowPrevious,
	allowResize,
	allowEndChat,
	boldTitle,
	allowToasts,
	allowMessageTemplates,
	allowChatTransfer,
	allowDownloadChat,
}: props): JSX.Element => {
	// Changes required
	// primary color
	// fallback message
	// transfer to fallback when no room
	// State
	// scroll
	const {
		messages: allMessages,
		appendMsg,
		resetList,
		setTyping,
		prependMsgs,
		deleteMsg,
		updateMsg,
	} = useMessages([]);
	const [quickReplies, setQuickReplies] = useState<QuickReply[]>([]);
	const { transcript, resetTranscript } = useSpeechRecognition();
	const [isError, setIsError] = useState<string | null>(null);
	const [chatService, setChatService] = useState(new ChatService(room));
	const [isLoading, setIsLoading] = useState(true);
	const [isLastMessageFetched, setisLastMessageFetched] = useState(false);
	const [isLocalstorageBlocked, setIsLocalstorageBlocked] = useState(false);
	const [user, setUser] = useState<{ imageUrl?: string; name?: string } | null>(null);
	const composerRef = useRef<{ setText: (text: string) => void }>();
	const recorderRef = useRef<RecorderHandle>() as MutableRefObject<RecorderHandle>;
	const speechText = useRef<string>('');
	const [toastProperties, setToastProperties] = useState<ToastProperties>({
		open: false,
		message: '',
		isError: false,
	});

	const [endChatModalData, setEndChatModalData] = useState<{
		open: boolean;
		roomToBeClosed: string | null;
	}>({ open: false, roomToBeClosed: null });
	const [templatesModal, setTemplatesModal] = useState<boolean>(false);

	/** ******************************************************** Helper functions ************************************************************* */
	// Logic
	async function fetchPastHistory(lastMessage?: ParsedMessage) {
		setIsLoading(true);
		if (!StorageService.isStorageEnabled()) {
			setIsLocalstorageBlocked(true);
			appendMsg(blockedStorageMessage);
			setIsLoading(false);
			return;
		}
		let messages;
		try {
			messages = await ChatService.fetchHistory(
				StorageService.application,
				room,
				lastMessage?._id as string,
			);
			if (messages.length < 50) {
				setisLastMessageFetched(true);
			}
			deleteMsg('0');
			if (!messages.map((message) => message._id).includes(firstMessage._id)) {
				messages.unshift(firstMessage);
			}
			prependMsgs(messages);
			setIsLoading(false);
		} catch (error: unknown) {
			const typedError = error as GenericError;
			setIsError(typedError?.statusCode === 403 ? FallbackType.FORBIDDEN : FallbackType.GENERIC);
		}
	}
	function handleSend(type: string, val: string) {
		if (type === 'text' && val.trim()) {
			if (StorageService.isStorageEnabled()) {
				chatService.sendMessage(val, StorageService.application);
			} else {
				appendMsg(blockedStorageMessage);
			}
		}
	}
	function handleQuickReplyClick(item: QuickReply) {
		handleSend('text', item.name);
		const input = document.querySelector('.Composer-input') as HTMLInputElement;
		if (input) {
			input.focus();
		}
	}
	async function startListening() {
		try {
			const permission = await navigator.permissions.query({
				name: 'microphone' as PermissionName,
			});
			if (permission.state === 'denied') {
				if (recorderRef?.current?.stop) {
					setTimeout(() => {
						recorderRef?.current?.stop();
					}, 300);
				}
				const errorMessage = Utils.createTextMessageObj({
					text: MESSAGES.NO_MIC_PERMISSION,
					messageCreator: MESSAGE_USER_TYPE.AGENT,
				});
				appendMsg(errorMessage);
			}
			await SpeechRecognition.startListening({ continuous: true, language: 'en-US' });
		} catch (error) {
			LoggerService.logError(error);
		}
	}
	async function endListening() {
		await SpeechRecognition.stopListening();
		if (composerRef?.current?.setText) {
			// had to fetch this way because composerRef does not provide getText.
			const textArea = document.getElementsByClassName('Composer-input');
			// old existing text in text area + new
			const textToBeSet = `${textArea[0].textContent} ${speechText.current}`.trim();
			composerRef.current.setText(textToBeSet);
		}
		// The below two line change the input type. This was the only way to do that without making changes in the library
		const inputChangeButton = document.getElementsByClassName(
			'Composer-inputTypeBtn',
		)[0] as HTMLButtonElement;
		inputChangeButton.click();

		resetTranscript();
	}
	async function cancelListening() {
		await SpeechRecognition.stopListening();
		resetTranscript();
	}

	const setToolbarElements = () => {
		const toolbarElements = [];
		if (allowMessageTemplates && !room?.endedAt) {
			toolbarElements.push({
				type: 'template',
				title: 'Message templates',
				// TODO: add a image for bookmark icon
				img: msgtemplates,
			});
		}
		if (allowDownloadChat) {
			toolbarElements.push({
				type: 'download',
				title: 'Download Chat',
				// TODO: add a image for bookmark icon
				img: downloadChats,
			});
		}

		return toolbarElements;
	};

	const openEndChatModal = async () => {
		if (room?._id) {
			setEndChatModalData({ open: true, roomToBeClosed: room._id });
		}
	};

	const closeEndChatModal = () => {
		setEndChatModalData({ open: false, roomToBeClosed: null });
	};

	const openPopover = () => {
		setTemplatesModal(true);
	};

	const closePopover = () => {
		setTemplatesModal(false);
	};

	const onTemplateSelect = (template: string) => {
		const message = template.includes('%name%')
			? template.replace('%name%', room?.title as string)
			: template;
		composerRef.current?.setText(message);
		closePopover();
	};

	const downloadChatsHandler = async (roomId: string) => {
		try {
			const response = await ApiService.downloadChat(roomId);
			// Ideally the headers were enough to download the file. But axios can't trigger the browser to download the file
			// https://medium.com/@drevets/you-cant-prompt-a-file-download-with-the-content-disposition-header-using-axios-xhr-sorry-56577aa706d6
			const href = URL.createObjectURL(response.data);
			const link = document.createElement('a');
			link.href = href;
			link.setAttribute('download', 'conversation.txt'); // or any other extension
			document.body.appendChild(link);
			link.click();
			// clean up "a" element & remove ObjectURL
			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (error) {
			setToastProperties({ open: true, message: MESSAGES.GENERIC_ERROR, isError: true });
		}
	};

	const handleToolbarClick = (toolbarClick: ToolbarItemProps) => {
		if (toolbarClick.type === 'template') {
			openPopover();
		} else if (toolbarClick.type === 'download' && room?._id) {
			downloadChatsHandler(room._id);
		}
	};

	const transferChatHandler = async (userId: string) => {
		try {
			await ApiService.transferChat(room?._id as string, userId);
		} catch (error) {
			LoggerService.logError(error, 'failed to transfer chat');
		}
	};

	function setFocusOnInputField() {
		let counter = 0;
		const interval = setInterval(() => {
			const inputFieldElement = document.querySelector('.chat-window-container') as HTMLElement;
			inputFieldElement.setAttribute('tabindex', '0');
			if (inputFieldElement === document.activeElement || counter >= 100) {
				clearInterval(interval);
			} else {
				inputFieldElement.focus();
			}
			counter += 1;
		}, 100);
	}

	/** ******************************************************* UseEffects ****************************************************************** */
	// Init
	useEffect(() => {
		setIsLoading(true);
		// Reset states
		resetList();
		setQuickReplies([]);
		resetTranscript();
		setIsError(null);
		setisLastMessageFetched(false);
		prependMsgs([firstMessage]);

		// Setup the ChatService
		const newChatService = new ChatService(room);
		newChatService.init();
		setChatService(newChatService);

		let quickReplySubscription: Subscription;
		if (allowQuickReplies) {
			quickReplySubscription = newChatService.quickReply$.subscribe((value) => {
				setQuickReplies(value);
			});
			setQuickReplies(mainMenuQuickReply);
		}

		if (fetchTitleAndAvatarFromStorage) {
			setUser({
				name: StorageService?.config?.brandingConfig?.title,
				imageUrl: StorageService?.config?.brandingConfig?.avatar,
			});
		} else {
			setUser({ name: room?.title, imageUrl: room?.imageUrl });
		}

		if (addListenerForBotFirstMessage) {
			window.addEventListener('message', (event: MessageEventsFromWidget) => {
				if (event.data.chatOpened && StorageService.isStorageEnabled()) {
					const history = StorageService.allMessages();
					if (history.length <= 1) {
						newChatService.sendMessage('Main Menu', StorageService.application, true);
					}
				}
				if (event?.data?.chatOpened) {
					setFocusOnInputField();
				}
			});
		}

		if (solo) {
			UIService.toggleChatWindow();
		}

		// Fetch conversations
		async function fetchData() {
			await fetchPastHistory();
		}
		fetchData();

		// Subscribe to messages
		const messageSubscription = newChatService.messages$.subscribe((newMessage) => {
			if (!messageIds.includes(newMessage?._id)) {
				messageIds.push(newMessage?._id);
				appendMsg(newMessage);
				if (allowQuickReplies && newMessage?.quickReplies?.length) {
					setQuickReplies(newMessage.quickReplies);
				}
				SfxService.playSound(newMessage?.user.type);
			} else {
				updateMsg(newMessage._id, newMessage);
			}
		});
		// Subscribe to typing
		const typingSubscription = newChatService.typing$.subscribe((value) => {
			setTyping(value as boolean);
		});

		return () => {
			newChatService.close();
			messageSubscription.unsubscribe();
			typingSubscription.unsubscribe();
			if (allowQuickReplies) {
				quickReplySubscription.unsubscribe();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [room]);
	useKeyboardShortcuts();
	useEffect(() => {
		speechText.current = transcript;
	}, [transcript]);

	return (
		<div className="chat-window-container hide-chat-window">
			<Header
				user={user}
				allowResize={allowResize}
				allowClose={allowClose}
				allowPrevious={allowPrevious}
				allowEndChat={Boolean(room && allowEndChat)}
				actionsHandler={handleActionsOnHeader} // TODO: improve this actions handler way
				allowChatTransfer={Boolean(room && allowChatTransfer)}
				boldTitle={boldTitle}
				disableEndChat={Boolean(room?.endedAt || room?.status === STATUS.DISABLED)}
				disableTransferChat={Boolean(room?.endedAt || room?.status === STATUS.DISABLED)}
				transferChatHandler={transferChatHandler}
				endChatHandler={openEndChatModal}
			/>
			{isError ? <Fallback type={isError} /> : null}
			{!isError && !renderWithEmptyRoom && !room ? (
				<Fallback type={FallbackType.NO_CHAT_SELECTED} />
			) : null}
			{!isError && ((!renderWithEmptyRoom && room) || renderWithEmptyRoom) && (
				<Chat
					locale="en-US"
					messages={allMessages}
					renderMessageContent={(msg) =>
						renderMessageContent(
							user as { imageUrl?: string; name?: string },
							msg as ParsedMessage,
							allowPagination as boolean,
							isLoading,
							isLastMessageFetched,
							isLocalstorageBlocked,
							fetchPastHistory,
							allMessages as ParsedMessage[],
						)
					}
					onSend={handleSend}
					quickReplies={quickReplies}
					onQuickReplyClick={handleQuickReplyClick}
					placeholder="Send a message..."
					inputType="text"
					composerRef={composerRef}
					recorder={{
						ref: recorderRef,
						canRecord: SpeechRecognition.browserSupportsSpeechRecognition(),
						onStart: startListening,
						onEnd: endListening,
						onCancel: cancelListening,
					}}
					toolbar={setToolbarElements()}
					onToolbarClick={handleToolbarClick}
				/>
			)}
			{allowIscBranding && StorageService.config.brandingConfig.iscBranding ? <Footer /> : null}
			{allowToasts && (
				<Toast
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...toastProperties}
					onClose={() => setToastProperties({ ...toastProperties, open: false })}
				/>
			)}
			{allowMessageTemplates && (
				<MessageTemplatesPopover
					open={templatesModal}
					closePopover={closePopover}
					onTemplateSelect={onTemplateSelect}
				/>
			)}
			{allowEndChat && <EndChatModal {...endChatModalData} closeModal={closeEndChatModal} />}
		</div>
	);
};

ChatWindow.defaultProps = {
	room: null,
	handleActionsOnHeader: null,
	allowEndChat: false, // Live Agent end Chat
	allowClose: false, // for header close
	allowResize: false, // resize allowed
	allowPrevious: false,
	allowQuickReplies: false, // quick replies are only allowed for chatbot	// UI Helpers
	allowMessageTemplates: false,
	fetchTitleAndAvatarFromStorage: false, // for chatbot
	addListenerForBotFirstMessage: false, // for chatbot
	solo: false, // if chatwindow is coupled with chatlist
	allowPagination: false, // pagination is not allowed for chatbot
	renderWithEmptyRoom: false, // with chatbot, we do not pass any room
	allowIscBranding: false, // this is not allowed for some applications
	boldTitle: false,
	allowToasts: false,
	allowChatTransfer: false,
	allowDownloadChat: false,
};

export default ChatWindow;
