/* eslint-disable react/jsx-props-no-spreading */
/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

// changes
// word wrap
// max width
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { Alert, Box, Button, TableSortLabel, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ApiService } from '../../services/ApiService';
import { MessageTemplate, ToastProperties } from '../../types';
import Toast from '../Toast/Toast';
import { MESSAGES } from '../../messages';
import { Loader } from '../Loader/Loader';
import { STATUS } from '../../enums/status.enum';
import { IscChip } from '../IscChip/IscChip';
import AddMessageTemplateModal from './Modals/Message-templates-add';
import EditMessageTemplateModal from './Modals/Message-templates-edit';
import useDebounce from '../../hooks/useDebounce';
import { SORT_DIRECTION } from '../../enums/sort-direction.enum';
import Searchbox from '../searchbox/Searchbox';
import { SORT_KEY } from '../../enums/sort-key.enum';

export const MessageTemplates = () => {
	const [toastProperties, setToastProperties] = useState<ToastProperties>({
		open: false,
		isError: false,
		message: '',
	});
	const [page, setPage] = useState<number>(0);
	const [isLoading, setisLoading] = useState<boolean>(false);
	const [total, setTotal] = useState<number>(0);
	const [rows, setRows] = useState<MessageTemplate[]>([]);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [addModal, setAddModal] = useState<boolean>(false);
	const [editModal, setEditModal] = useState<boolean>(false);
	const [editModalData, setEditModalData] = useState<{
		_id: string;
		title: string;
		template: string;
	}>({ _id: '', title: '', template: '' });
	const [sortParams, setSortParams] = useState({
		key: SORT_KEY.TITLE,
		direction: SORT_DIRECTION.ASC,
	});
	const [searchData, setSearchData] = useState<string | null>(null);
	const debouncedSearchText = useDebounce(searchData, 1000);
	const openMenu = Boolean(anchorEl);
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	/* ************************************************************* Helper functions **************************************************** */
	const fetchMessageTemplates = async (data?: {
		sortKey?: string;
		sortDirection?: string;
		searchText?: string | null;
		page?: number;
	}) => {
		try {
			setisLoading(true);
			const response = await ApiService.fetchMessageTemplates({
				...data,
				searchText: data?.searchText || debouncedSearchText,
				page: data && data.page !== undefined ? data.page + 1 : page + 1,
				limit: 10,
			});
			setRows(response.data.data);
			setTotal(response.data.meta?.total);
		} catch (error) {
			setToastProperties({ open: true, message: MESSAGES.GENERIC_ERROR, isError: true });
		} finally {
			setisLoading(false);
		}
	};
	const handleChangePage = (data: unknown, newPage: number) => {
		setPage(newPage);
		fetchMessageTemplates({
			page: newPage,
			sortKey: sortParams.key,
			sortDirection: sortParams.direction,
		});
	};

	const updateMessageTemplateStatus = async (id: string, disable: boolean) => {
		try {
			const response = await ApiService.updateMessageTemplate({
				status: disable ? STATUS.DISABLED : STATUS.ACTIVE,
				_id: id,
			});
			setToastProperties({
				open: true,
				message: response?.data.message,
				isError: false,
			});
		} catch (error) {
			const typedError = error as AxiosError;
			setToastProperties({
				open: true,
				message: typedError.response?.data.message || MESSAGES.GENERIC_ERROR,
				isError: true,
			});
		} finally {
			handleClose();
			fetchMessageTemplates();
		}
	};

	const onSearch = (search: string | null) => {
		setSearchData(search);
	};

	const sortHandler = (sortKey: SORT_KEY) => {
		let direction = SORT_DIRECTION.ASC;
		if (sortParams.key === sortKey) {
			direction =
				sortParams.direction === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC;
		}
		setSortParams({ key: sortKey, direction });
	};

	/* **************************************************************** Side effects ***************************************************** */

	/**
	 * Initialization
	 */
	useEffect(() => {
		fetchMessageTemplates();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setPage(0);
		if (debouncedSearchText) {
			fetchMessageTemplates({ searchText: debouncedSearchText, page: 0 });
		} else {
			fetchMessageTemplates();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchText]);

	useEffect(() => {
		fetchMessageTemplates({
			sortKey: sortParams.key,
			sortDirection: sortParams.direction,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortParams]);

	/* **************************************************************** Components ***************************************************** */
	const renderTable = () => (
		<TableContainer component={Paper} sx={{ boxShadow: 'none', border: '0px', mt: '10px' }}>
			<Table sx={{ width: '100%' }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell align="left" style={{ minWidth: 170, fontWeight: 600 }}>
							Template
							<TableSortLabel
								active={sortParams.key === SORT_KEY.TITLE}
								direction={
									sortParams.key === SORT_KEY.NAME ? sortParams.direction : SORT_DIRECTION.ASC
								}
								onClick={() => {
									sortHandler(SORT_KEY.TITLE);
								}}
							/>
						</TableCell>
						<TableCell align="left" style={{ minWidth: 170, fontWeight: 600 }}>
							Status
						</TableCell>
						<TableCell align="left" style={{ minWidth: 170, fontWeight: 600 }}>
							Action
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows &&
						rows.map((row) => (
							<TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell align="left" colSpan={1} style={{ wordBreak: 'break-word' }}>
									{row.title} <br />
									<span className="text-muted"> {row.template} </span>
								</TableCell>
								<TableCell align="left">
									<IscChip status={row.status as STATUS} />
								</TableCell>
								<TableCell align="left">
									<Button
										variant="outlined"
										sx={{
											color: '#2C405A',
											textTransform: 'capitalize',
											borderColor: '#E4E8EC',
											pr: 1,
											pl: 2,
										}}
										data-status={row.status}
										data-id={row._id}
										id="basic-button"
										aria-controls={openMenu ? 'basic-menu' : undefined}
										aria-haspopup="true"
										aria-expanded={openMenu ? 'true' : undefined}
										onClick={handleClick}>
										Edit
										<ArrowDropDownIcon sx={{ ml: 1 }} />
									</Button>
								</TableCell>
							</TableRow>
						))}
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[]}
							colSpan={10}
							count={total}
							rowsPerPage={10}
							page={page}
							onPageChange={handleChangePage}
							sx={{ border: 'none' }}
						/>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);

	const renderMenu = () => (
		<Menu
			id="basic-menu"
			anchorEl={anchorEl}
			open={openMenu}
			onClick={handleClose}
			MenuListProps={{
				'aria-labelledby': 'basic-button',
			}}>
			{anchorEl?.dataset.status === STATUS.ACTIVE && (
				<MenuItem
					onClick={() => {
						const template = rows.find((row) => row._id === anchorEl.dataset.id);
						setEditModalData({
							_id: template?._id as string,
							title: template?.title as string,
							template: template?.template as string,
						});
						setEditModal(true);
					}}>
					Edit
				</MenuItem>
			)}
			{anchorEl?.dataset.status === STATUS.ACTIVE && (
				<MenuItem onClick={() => updateMessageTemplateStatus(anchorEl.dataset.id as string, true)}>
					Disable
				</MenuItem>
			)}
			{anchorEl?.dataset.status === STATUS.DISABLED && (
				<MenuItem onClick={() => updateMessageTemplateStatus(anchorEl.dataset.id as string, false)}>
					Enable
				</MenuItem>
			)}
		</Menu>
	);

	return (
		<Box sx={{ p: 3, pb: 1 }}>
			<Box
				sx={{
					display: 'flex',
					direction: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					p: 1,
				}}>
				<Typography typography="h6">Quick Replies</Typography>
				<Button
					variant="contained"
					onClick={() => setAddModal(true)}
					sx={{ textTransform: 'capitalize' }}
					startIcon={<AddIcon />}>
					New Quick Reply
				</Button>
			</Box>
			<Searchbox
				searchData={searchData}
				setSearchData={onSearch}
				placeholder="Search quick-replies"
			/>
			{isLoading && (
				<Box
					sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pt: 10, pb: 15 }}>
					<Loader size="regular" />
				</Box>
			)}
			{!isLoading && !rows?.length && (
				<Alert severity="warning" sx={{ mt: 10, mb: 10 }}>
					No message templates found
				</Alert>
			)}
			{!isLoading && rows.length !== 0 && renderTable()}
			{renderMenu()}
			<AddMessageTemplateModal
				open={addModal}
				onClose={(value) => {
					setAddModal(false);
					if (value) {
						fetchMessageTemplates();
					}
				}}
			/>
			<EditMessageTemplateModal
				prefillData={editModalData}
				open={editModal}
				onClose={(value) => {
					setEditModal(false);
					if (value) {
						fetchMessageTemplates();
					}
				}}
			/>
			<Toast
				{...toastProperties}
				onClose={() => setToastProperties({ ...toastProperties, open: false })}
			/>
		</Box>
	);
};
