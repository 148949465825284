/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { FC } from 'react';

interface props {
	message?: string;
	isError?: boolean;
	open?: boolean;
	onClose?: (() => void) | null;
}

// modify state
// force render
const Toast: FC<props> = ({ message, isError, open, onClose }: props): JSX.Element => (
	<Snackbar
		anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
		onClose={onClose || undefined} // because on close was not accepting null
		open={open}
		autoHideDuration={6000}>
		<Alert variant="filled" severity={isError ? 'error' : 'success'}>
			{message}
		</Alert>
	</Snackbar>
);

Toast.defaultProps = {
	message: '',
	isError: false,
	open: false,
	onClose: null,
};

export default Toast;
