/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { Bubble, RichText } from '@isc/chatui-core';
import { format } from 'date-fns';
import { Utils } from '../../services/Utils';
import { ParsedMessage, MessageContentText } from '../../types';
import './Text.css';

interface props {
	content: MessageContentText;
	position: ParsedMessage['position'];
	user: ParsedMessage['user'];
	dateAndTime: ParsedMessage['dateAndTime'];
}

export const Text = ({ content, position, user, dateAndTime }: props): JSX.Element => (
	<Bubble>
		{user?.name && <h4 className="message-name">{Utils.getTrimmedText(user.name, 25)}</h4>}
		<RichText content={Utils.linkifyAndSanitizeWithWhitelistedTags(content.text).trim()} />
		{dateAndTime && (
			<h4 className={position === 'left' ? 'message-time' : 'message-time right-align'}>
				{format(new Date(dateAndTime), 'd MMM, h:mm bbb')}
			</h4>
		)}
	</Bubble>
);
