/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Applications } from './enums/applications.enum';
import './App.css';
import ChatWindow from './components/ChatWindow/ChatWindow';
import { StorageService } from './services/StorageService';
import { ApiService } from './services/ApiService';
import { UIService } from './services/UIService';
import { PushNotificationService } from './services/PushNotificationService';
import { Base } from './components/Base/Base';
import { LoggerService } from './services/LoggerService';
import { Login } from './components/Auth/Login/Login';
import { Chat } from './components/Chat/Chat';
import { Loader } from './components/Loader/Loader';
import { ForgotPassword } from './components/Auth/Forgot-password/Forgot-password';
import { liveAgentDashboardThemingConfig } from './configs';
import { ResetPassword } from './components/Auth/Reset-password/Reset-password';
import AuthenticatedRoute from './route-guards/authenticated-route';

interface EventFromWidget extends MessageEvent {
	data: {
		application?: Applications;
		apiKey?: string;
		token?: string;
	};
}

const chatbotApplications = [
	Applications.CHATBOT,
	Applications.CHATBOT_GPT,
	Applications.CHATBOT_GPT_V2,
];

/**
 * responsible for
 * initial setup
 * rendering first
 */
const App = (): JSX.Element => {
	const [isLoading, isSetLoading] = useState<boolean>(true);
	const [rootElement, setRootElement] = useState<JSX.Element>();

	const initializeChat = async (event: EventFromWidget) => {
		// Message from the widget
		if (event.data.application) {
			if (chatbotApplications.includes(event.data.application) && event.data.apiKey) {
				StorageService.application = event.data.application;
				StorageService.tenantApiKey = event.data.apiKey;
				const config = await ApiService.fetchTenantConfig(false);
				UIService.setTheme(config);
				StorageService.config = config;
				PushNotificationService.sendMessage({ config });
				setRootElement(
					<ChatWindow
						allowClose
						allowQuickReplies={event.data.application !== Applications.CHATBOT_GPT}
						fetchTitleAndAvatarFromStorage
						addListenerForBotFirstMessage
						renderWithEmptyRoom
						solo
						allowIscBranding
						boldTitle
					/>,
				);
				isSetLoading(false);
			} else if (event.data.application === Applications.EXTERNAL_CHAT) {
				setRootElement(<Chat />);
				StorageService.application = event.data.application;
			}
		}
		// Message from the authenticated website
		if (event.data.token) {
			try {
				if (StorageService.application === Applications.EXTERNAL_CHAT) {
					StorageService.accessToken = event.data.token;
					const config = await ApiService.fetchTenantConfig(true);
					const user = await ApiService.fetchUserInfo();
					UIService.setTheme(config);
					StorageService.config = config;
					StorageService.authUser = user;
					PushNotificationService.sendMessage({ config });
					isSetLoading(false);
				}
			} catch (err) {
				LoggerService.logError(err);
			}
		}
	};

	const checkUrlParams = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const application = urlParams.get('application');
		const apiKey = urlParams.get('apikey');
		const token = urlParams.get('token');
		if (application || apiKey || token) {
			initializeChat({
				data: {
					application: application as Applications,
					apiKey: apiKey as string,
					token: token as string,
				},
			} as EventFromWidget);
		}
	};

	useEffect(() => {
		// TODO: remove the below comment after review and figure out a way to seperate out both the applications in future
		// attach listeners only if application is not live agent dashboard
		if (process.env.REACT_APP_APPLICATION !== Applications.LIVE_AGENT_DASHBOARD) {
			checkUrlParams();
			window.addEventListener('message', (event) => initializeChat(event));
			window.parent.postMessage({ iframeLoaded: true }, '*');
		}

		if (process.env.REACT_APP_APPLICATION === Applications.LIVE_AGENT_DASHBOARD) {
			UIService.cssThemingOverridesForLiveAgent();
			document.title = 'Live Agent Dashboard';
			StorageService.application = process.env.REACT_APP_APPLICATION;
			UIService.setTheme(liveAgentDashboardThemingConfig);
			setRootElement(
				<Router>
					<Routes>
						<Route
							path="/*"
							element={
								<AuthenticatedRoute>
									<Base />
								</AuthenticatedRoute>
							}
						/>
						<Route
							path="/:id/*"
							element={
								<AuthenticatedRoute>
									<Base />
								</AuthenticatedRoute>
							}
						/>
						<Route path="/login" element={<Login />} />
						<Route path="/forgot-password" element={<ForgotPassword />} />
						<Route path="/reset-password" element={<ResetPassword />}>
							<Route path=":resetToken" element={<ResetPassword />} />
						</Route>
					</Routes>
				</Router>,
			);
			isSetLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{isLoading && <Loader size="regular" />}
			{!isLoading && rootElement}
		</>
	);
};

export default App;
