/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
export enum USER_ROLES {
	AGENT = 'AGENT',
	ADMIN = 'ADMIN',
	EXTERNAL_USER = 'EXTERNAL_USER',
}
