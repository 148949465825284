/* eslint-disable max-len */
/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
export const MESSAGES = {
	NO_LOCAL_STORAGE_ACCESS:
		'The chatbot needs localStorage to be enabled for better performance and functionality. Please refer to the below link on how to enable it.',
	NO_MIC_PERMISSION: 'Please check if the browser permissions to access the microphone is enabled',
	NO_CHAT_LIST_TITLE: 'No Students!',
	NO_CHAT_LIST_SUBTITLE: 'Once you are assigned a student you will we able to chat here.',
	NO_CHAT_LIST_AFTER_SEARCH_TITLE: 'Sorry! No results found.',
	NO_CHAT_LIST_AFTER_SEARCH_SUBTITLE:
		"We searched far and wide but couldn't find anything matching your search.",
	NO_CHAT_SELECTED_TITLE: 'No chat selected',
	NO_CHAT_SELECTED_SUBTITLE: 'Select a chat from left menu and <br/> start your conversation',
	FORBIDDEN_TITLE: 'Forbidden',
	FORBIDDEN_ERROR: 'You do not have the required permissions to access this functionality.',
	GENERIC_ERROR: 'Oops! There was an error. Please try again later.',
	GENERIC_TITLE: 'Failed to load',
	GENERIC_CHAT_ERROR:
		'Either something went wrong or there’s no internet connection. Please, refresh the page and try again.',
	PASSWORD_NO_MATCH: 'Password miss match.',
	NO_ACTIVE_CHATS: 'No active chats',
	NO_RECENT_CHATS: 'No past chats',
	MAX_TITLE: 'You have exceeded the maximum limit of 20 characters.',
	EXCEEDED_CHARACTERS_50: 'You have exceeded the maximum limit of 50 characters.',
	MAX_TEMPLATE: 'You have exceeded the maximum limit of 2048 characters.',
	WAITING_USERS: 'You have new users waiting for your response',
	EXCEEDED_CHARACTERS: 'The entered value exceeds the character limit.',
	REQUIRED_FIRST_NAME: 'First Name is required',
	REQUIRED_LAST_NAME: 'Last Name is required',
	INVALID_EMAIL: 'Please enter a valid email ID',
	DISABLED_USER_ACCOUNT:
		'Your access for this account has been disabled. Kindly contact your admin for more details',
	ROOM_CLOSED:
		"Thank you for using our live chat service. We are now closing this chat. If you have any more issues, please don't hesitate to let us know. Have a great day!",
};
