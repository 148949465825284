/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { Subject } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { CLIENT_TRIGGERED_EVENTS, RawMessageFromSocket, SERVER_TRIGGERED_EVENTS } from '../types';
import { LoggerService } from './LoggerService';
// import { StorageService } from './StorageService';

const API_URL = process.env.REACT_APP_API_URL as string;

interface Notification {
	type: SERVER_TRIGGERED_EVENTS;
	roomId: string;
	userId: string;
	lastMessage: string;
	lastMessageAt: Date;
	members?: { [key: string]: { is_read: boolean } };
}

// setups socket
// handles events
// forwards those events to the caller via the subject
// closes socket
export class UserNotificationService {
	notifications$ = new Subject<Notification>();

	accessToken: string | null;

	private socket: Socket | null = null;

	constructor(token: string | null) {
		this.accessToken = token;
	}

	// Socket
	private setupSocket() {
		this.socket = io(`${API_URL}/push-notification-user`, {
			transports: ['websocket'],
			reconnection: true,
			auth: {
				accessToken: this.accessToken,
			},
		});
		const onConnection = () => {
			// eslint-disable-next-line no-console
			console.log('Socket connected...');
			this.socket?.emit(CLIENT_TRIGGERED_EVENTS.JOIN, {});
		};
		const onDisconnection = () => {
			// TODO: Some handling
			// eslint-disable-next-line no-console
			console.log('Socket disconnected...');
		};
		const onMessageReceived = (rawLiveAgentMessage: RawMessageFromSocket[]) => {
			rawLiveAgentMessage.forEach((messageObject) => {
				this.notifications$.next({
					type: SERVER_TRIGGERED_EVENTS.MESSAGE,
					roomId: messageObject.room_id,
					userId: messageObject.user_id as string,
					lastMessage: messageObject.content.text as string,
					lastMessageAt: new Date(),
					members: messageObject.members,
				});
			});
		};
		const onClose = (message: { room_id: string }) => {
			this.notifications$.next({
				type: SERVER_TRIGGERED_EVENTS.CLOSE,
				roomId: message.room_id,
				userId: '',
				lastMessage: '',
				lastMessageAt: new Date(),
			});
		};
		this.socket.on('connect', onConnection);
		this.socket.on('disconnect', onDisconnection);
		this.socket.on(SERVER_TRIGGERED_EVENTS.CLOSE, onClose);
		this.socket.on(SERVER_TRIGGERED_EVENTS.MESSAGE, onMessageReceived);
		this.socket.on('exception', LoggerService.logError);
	}

	init(): void {
		this.setupSocket();
	}

	close(): void {
		this.socket?.disconnect();
	}
}
