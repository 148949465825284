/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
export const CONFIG = {
	CUT_OFF_DAYS: 2,
};

export const ChatWindowPropsForExternalChat = {
	allowClose: true, // for header close
	allowResize: true, // resize allowed
	allowPagination: true, // pagination is not allowed for chatbot
	allowIscBranding: false, // this is not allowed for some applications
	allowPrevious: true,
};

export const ChatWindowPropsForLiveAgentDashboard = {
	allowEndChat: true, // Live Agent end Chat
	allowPagination: true, // pagination is not allowed for chatbot
	renderWithEmptyRoom: false, // with chatbot, we do not pass any room
	allowMessageTemplates: true,
	allowChatTransfer: true,
	allowPrevious: true,
	allowDownloadChat: true,
	allowToasts: true,
};

export const ChatListPropsForExternalChat = {
	allowClose: true,
	allowResize: true,
	roomSearch: true,
	requireChatOpenedListener: true,
};

export const ChatListPropsForLiveAgentDashboard = {
	tabs: true,
	assignSelfButton: true,
	requireTenantNotification: true,
	allowToasts: true,
};

export const liveAgentDashboardThemingConfig = {
	_id: '',
	name: '',
	brandingConfig: {
		theme: {
			primary: 'rgb(0,180,247)',
			secondary: 'rgb(0,180,247)',
			tertiary: 'rgba(0, 180, 247, 0.2)',
		},
		favicon:
			'https://cdn.ischoolconnect.com/accounts/2fc52ed3-b5f8-4ddb-843c-3d7c7917f63d/favicon/favicon-144x144.png',
	},
};

export const emailRegex =
	/^(?=[^@]{0,64}@)([a-zA-Z0-9!#$%&*+\x2f=?^_`{|}~-]+(?:[.][a-zA-Z0-9!#$%&*+\x2f=?^_`{|}~-]+)*)@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/gm;
