/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

// TODO: Get this whole component reviewed from someone who's well versed with React

import { Input, List, ListItem, Notice, Tag, Text } from '@isc/chatui-core';
import { FC, useEffect, useRef, useState } from 'react';
import Avatar from 'react-avatar';
import _ from 'lodash';
import { Box, Button, Tooltip, useTheme } from '@mui/material';
import { Subscription } from 'rxjs';
import { AxiosError } from 'axios';
import PersonIcon from '@mui/icons-material/Person';
import { ApiService } from '../../services/ApiService';
import { UserNotificationService } from '../../services/UserNotificationService';
import { PushNotificationService } from '../../services/PushNotificationService';
import { StorageService } from '../../services/StorageService';
import { Utils } from '../../services/Utils';
import {
	DEVICE_TYPE,
	GenericError,
	ParsedRoom,
	SERVER_TRIGGERED_EVENTS,
	ToastProperties,
} from '../../types';
import { Fallback, FallbackType } from '../Fallback/Fallback';
import Header from '../Header/Header';
import { Loader } from '../Loader/Loader';
import './ChatList.css';
import { UIService } from '../../services/UIService';
import { LoggerService } from '../../services/LoggerService';
import { TenantNotificationService } from '../../services/TenantNotificationService';
import { SfxService } from '../../services/SfxService';
import Toast from '../Toast/Toast';
import { MESSAGES } from '../../messages';

// handle actions of unassigned button
// when unassigned button is clicked
// we fetch a room
// and we set that room
// that's it.

export interface ChatListProps {
	onRoomSelect: (room: ParsedRoom | null) => void;
	currentRoom: ParsedRoom | null;
	tabs?: boolean;
	assignSelfButton?: boolean;
	roomSearch?: boolean;
	requireTenantNotification?: boolean;
	allowToasts?: boolean; // Toast is allowed for chatbot and student advisor chat
	allowClose?: boolean;
	allowResize?: boolean;
	requireChatOpenedListener?: boolean;
}

const ChatListNew: FC<ChatListProps> = ({
	onRoomSelect,
	currentRoom,
	roomSearch,
	tabs,
	assignSelfButton,
	requireTenantNotification,
	allowToasts,
	requireChatOpenedListener,
	allowClose,
	allowResize,
}: ChatListProps): JSX.Element => {
	// End chat issue
	// set room on tab change
	// issue with assign agent
	const [searchText, setSearchText] = useState<string>('');
	const [isError, setIsError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isRefreshRequired, setIsRefreshRequired] = useState<boolean>(false);
	const [roomsToBeRendered, setRoomsToBeRendered] = useState<ParsedRoom[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [selectedRoom, setSelectedRoom] = useState<string | null>(null);
	const [, setChatOpened] = useState<boolean>(false);
	const debouncedRoomClick = useRef<ReturnType<typeof setTimeout> | null>(null);
	const [activeRooms, setActiveRooms] = useState<boolean>(true);
	const [waitingUsersCount, setWaitingUsersCount] = useState<number>(0);
	const [assignSelfLoading, setAssignSelfLoading] = useState<boolean>(false);
	const [toastProperties, setToastProperties] = useState<ToastProperties>({
		open: false,
		message: '',
		isError: false,
	});
	let assignSelfFlag = false;
	const theme = useTheme();
	// active rooms, setter
	// a use effect depending on this state
	// this useeffect basically fetches rooms based on the flag
	// the buttons will set the respective state
	// rest remains the same
	// for external chat, default active will be used
	// Logic

	/* **************************************************** Helper functions ***************************************************** */
	const fetchRooms = async (page: number) => {
		try {
			setIsError(null);
			setIsLoading(true);
			const response = await ApiService.fetchRooms({ searchText, limit: 10, page, activeRooms });
			let totalUnreadCount = 0;
			response.rooms.forEach(
				// eslint-disable-next-line no-return-assign
				(returnedRooms) => (totalUnreadCount += returnedRooms?.unreadCount || 0),
			);
			PushNotificationService.updateUnreadCount({ count: totalUnreadCount });
			if (page > 1) {
				setRoomsToBeRendered((oldRooms) => oldRooms.concat(response.rooms));
			} else {
				setRoomsToBeRendered(response.rooms);
			}
			setTotal(response.total);
			setIsError(null);
		} catch (error) {
			LoggerService.logError(error);
			const typedError = error as GenericError;
			setIsError(typedError?.statusCode === 403 ? FallbackType.FORBIDDEN : FallbackType.GENERIC);
		} finally {
			setIsLoading(false);
		}
	};

	const refreshRooms = async () => {
		setRoomsToBeRendered([]);
		setSearchText('');
		setIsRefreshRequired(false);
		await fetchRooms(1);
	};

	const itemClickHandler = (roomSelectedByClick: ParsedRoom) => {
		if (debouncedRoomClick.current) {
			clearTimeout(debouncedRoomClick.current);
			debouncedRoomClick.current = null;
		}
		debouncedRoomClick.current = setTimeout(() => {
			UIService.toggleChatWindow();
			// TODO: transfer the below line to useEffect
			onRoomSelect(roomSelectedByClick);
			if (roomSelectedByClick.unreadCount) {
				ApiService.markRoomAsRead(roomSelectedByClick._id);
				PushNotificationService.updateUnreadCount({ count: 0 - roomSelectedByClick.unreadCount });
				// eslint-disable-next-line no-param-reassign
				roomSelectedByClick.unreadCount = 0;
			}
			setSelectedRoom(roomSelectedByClick._id);
			setRoomsToBeRendered((rooms) =>
				rooms.map((room) => {
					if (room._id === roomSelectedByClick._id) {
						// eslint-disable-next-line no-param-reassign
						room.unreadCount = null;
					}
					return room;
				}),
			);
		}, 500);
	};

	const setNoRoomSelectedState = () => {
		onRoomSelect(null);
		setSelectedRoom(null);
	};

	const getLatestSelectedRoom = () => {
		// Had to take this approach because react useState is asynchronous. it batches updates for performance reasons.
		// While the update is reflected immediately on UI, it may not get reflected here.
		// https://stackoverflow.com/questions/38558200/react-setstate-not-updating-immediately
		let latestSelectedRoom: string | null | undefined;
		setSelectedRoom((currentState) => {
			latestSelectedRoom = currentState;
			return currentState;
		});
		return latestSelectedRoom;
	};

	const getLatestChatOpened = () => {
		// Had to take this approach because react useState is asynchronous.
		// it batches updates for performance reasons.
		// While the update is reflected immediately on UI, it may not get reflected here.
		// https://stackoverflow.com/questions/38558200/react-setstate-not-updating-immediately
		let latestChatOpened;
		setChatOpened((currentState) => {
			latestChatOpened = currentState;
			return currentState;
		});
		return latestChatOpened;
	};

	const assignSelf = async () => {
		try {
			setAssignSelfLoading(true);
			await ApiService.assignSelf();
			assignSelfFlag = true;
			setActiveRooms(true);
			// the room will be received from backend via socket notifications
		} catch (error) {
			const typedError = error as AxiosError;
			const message =
				typedError.response?.status === 404
					? typedError.response.data.message
					: MESSAGES.GENERIC_ERROR;
			setToastProperties({ open: true, message, isError: true });
			setAssignSelfLoading(false);
		}
	};

	/* ************************************************************ Side effects  ***************************************************** */
	// Init
	useEffect(() => {
		const userNotificationService = new UserNotificationService(StorageService.accessToken);
		userNotificationService.init();
		const userNotification$ = userNotificationService.notifications$.subscribe(
			async (notification) => {
				if (notification.type === SERVER_TRIGGERED_EVENTS.MESSAGE) {
					let roomExists = false;
					const notificationData = {
						title: '',
						text: '',
					};
					setRoomsToBeRendered((oldRooms) =>
						oldRooms
							.map((oldroom) => {
								if (oldroom._id === notification.roomId) {
									roomExists = true;
									const latestSelectedRoom = getLatestSelectedRoom();
									const latestChatOpened = getLatestChatOpened();
									if (StorageService.authUser._id !== notification.userId) {
										// Update unread count
										if (oldroom._id !== latestSelectedRoom || !latestChatOpened) {
											// eslint-disable-next-line no-param-reassign
											oldroom.unreadCount = oldroom.unreadCount ? oldroom.unreadCount + 1 : 1;
											PushNotificationService.updateUnreadCount({ count: 1 });
										}
										// mark room as read
										if (oldroom._id === latestSelectedRoom) {
											ApiService.markRoomAsRead(oldroom._id);
										}
										// Push Notifications setup. Should add only one message here
										// because there's only one new message here.
										notificationData.title = oldroom.title;
										notificationData.text = notification.lastMessage;
									}
									oldroom.lastMessage = notification.lastMessage;
									oldroom.lastMessageAt = notification.lastMessageAt;
								}
								return oldroom;
							})
							.sort(
								(a, b) => new Date(b.lastMessageAt).getTime() - new Date(a.lastMessageAt).getTime(),
							),
					);

					// logic to remove guest when it is transferred to another agent. Ideally there should be a socket call
					// TODO: figure out a socket call for transfer user
					// the tabs is something that is indicating that this is live agent dashboard
					// the below logic is makeshift temporary logic. eventually we will handle this using more events from backend
					if (notification.members && tabs) {
						const members = Object.keys(notification.members);
						if (members.length) {
							const findResult = members.find((key) => key === StorageService.authUser._id);
							if (!findResult) {
								setNoRoomSelectedState();
								setRoomsToBeRendered((oldRooms) => {
									const index = oldRooms.findIndex(
										(oldRoom) => oldRoom._id === notification.roomId,
									);
									if (index > -1) {
										oldRooms.splice(index, 1);
									}
									return [...oldRooms];
								});
							}
						}
					}

					// new guest room notification after assign room API is called.
					if (!roomExists) {
						try {
							// Fetch the room, append to the list
							const newRoom = await ApiService.fetchRoom(notification.roomId);
							if (newRoom) {
								setAssignSelfLoading(false);
								setRoomsToBeRendered((oldRooms) => {
									oldRooms.unshift(newRoom);
									// eslint-disable-next-line max-len
									return oldRooms.filter(
										(oldRoom, idx, array) => idx === array.findIndex((r) => r._id === oldRoom._id),
									);
								});
								if (tabs) {
									// tabs are sent for live agent dashboard
									itemClickHandler(newRoom);
									// onRoomSelect(newRoom);
								}
								if (!assignSelfFlag) {
									notificationData.text = 'A new user has been transferred';
								}
								// eslint-disable-next-line react-hooks/exhaustive-deps
								assignSelfFlag = false;
								// setIsRefreshRequired(true);
							}
						} catch (err) {
							LoggerService.logError(err, 'Failed to fetch rooms');
						}
					}
					PushNotificationService.sendUnreadMessageNotification(notificationData);
				}
				if (notification.type === SERVER_TRIGGERED_EVENTS.CLOSE) {
					setRoomsToBeRendered((oldRooms) => {
						const index = oldRooms.findIndex((room) => room._id === notification.roomId);
						if (index > -1) {
							PushNotificationService.sendUnreadMessageNotification({
								title: '',
								text: `${oldRooms[index].title} has left the conversation`,
							});
							oldRooms.splice(index, 1);
						}
						setNoRoomSelectedState();
						// https://stackoverflow.com/questions/25937369/react-component-not-re-rendering-on-state-change
						// so, basically, modifying and returning the same variable will not cause react to rerender
						// because for react, it is the same reference.
						return [...oldRooms];
					});
				}
			},
		);

		const tenantNotificationService = new TenantNotificationService(StorageService.accessToken);
		let tenantNotification$: Subscription;
		if (requireTenantNotification) {
			tenantNotificationService.init();
			tenantNotification$ = tenantNotificationService.notifications$.subscribe(
				(data: { count: number }) => {
					if (data) {
						setWaitingUsersCount(data.count);
						// TODO: show notification
						if (data.count) {
							PushNotificationService.sendUnreadMessageNotification({
								title: '',
								text: MESSAGES.WAITING_USERS,
							});
							SfxService.playWaitingUserSound();
						} else {
							SfxService.stopWaitingUserSound();
						}
					}
				},
			);
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let chatOpenedListener: any;
		if (requireChatOpenedListener) {
			chatOpenedListener = (event: { data: { chatOpened: boolean; type?: string } }) => {
				if (event.data.type === 'chatOpened') {
					setChatOpened(event.data.chatOpened);
					if (event.data.chatOpened) {
						const latestSelectedRoom = getLatestSelectedRoom();
						setRoomsToBeRendered((rooms) =>
							rooms.map((room) => {
								if (room._id === latestSelectedRoom && room.unreadCount) {
									PushNotificationService.updateUnreadCount({ count: 0 - room.unreadCount });
									room.unreadCount = 0;
									ApiService.markRoomAsRead(room._id);
								}
								return room;
							}),
						);
					}
				}
			};
			window.addEventListener('message', chatOpenedListener);
		} else {
			setChatOpened(true);
		}

		return () => {
			tenantNotification$?.unsubscribe();
			tenantNotificationService.close();
			userNotification$.unsubscribe();
			userNotificationService.close();
			if (requireChatOpenedListener) {
				window.removeEventListener('message', chatOpenedListener);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!currentRoom) {
			setSelectedRoom(null);
		}
	}, [currentRoom]);

	// debounce search
	useEffect(() => {
		const debouncedRoomSearchTimeout = setTimeout(() => {
			setRoomsToBeRendered([]);
			fetchRooms(1);
		}, 500);
		return () => clearTimeout(debouncedRoomSearchTimeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchText]);

	// to fetch active/recent rooms
	useEffect(() => {
		const actions = async () => {
			await fetchRooms(1);
			setRoomsToBeRendered((rooms) => {
				if (Utils.getDeviceType() === DEVICE_TYPE.DESKTOP) {
					itemClickHandler(rooms[0]);
				}
				return rooms;
			});
			// console.log(rooms)
			// itemClickHandler(roomsToBeRendered[0]);
		};
		if (tabs) {
			actions();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeRooms]);

	/* ***************************************************** UI Helpers ******************************************************** */
	const renderLoader = () => (
		<div className="loader-container">
			<Loader size="regular" />
		</div>
	);
	const renderListItem = (room: ParsedRoom) => {
		let showUnreadCount = false;
		let unreadCount = '';
		if (room.unreadCount) {
			unreadCount = room.unreadCount.toString();
			if (room.unreadCount > 9) {
				unreadCount = '9+';
			}
			showUnreadCount = true;
		}
		return (
			<ListItem
				key={room._id}
				className={selectedRoom === room._id ? 'list-item-selected' : ''}
				onClick={() => itemClickHandler(room)}>
				<div className="list-item-container">
					<div className="avatar-and-body-container">
						<div className="avatar-container">
							<Avatar src={room.imageUrl} round size="40" name={room.title} maxInitials={3} />
						</div>
						<div className="body-container">
							<Text className={`item-title${room.unreadCount ? ' item-bold' : ''}`}>
								{Utils.getTrimmedText(room.title)}
							</Text>
							<Text className={`item-subtitle${room.unreadCount ? ' item-bold' : ''}`}>
								{_.unescape(room.lastMessage)}
							</Text>
						</div>
					</div>
					{showUnreadCount && (
						<div className="unread-count-container">
							<Tag className="unread-count">{unreadCount}</Tag>
						</div>
					)}
				</div>
			</ListItem>
		);
	};

	const renderList = (rooms: ParsedRoom[]) => (
		<List className="chat-list" bordered>
			{rooms.map((room) => renderListItem(room))}
			{total && rooms.length < total && !isLoading && (
				<ListItem
					onClick={() => {
						fetchRooms(Math.ceil(rooms.length / 20) + 1);
					}}
					className="chat-list-item-center">
					Show More
				</ListItem>
			)}
			{total && rooms.length < total && isLoading && (
				<ListItem className="chat-list-item-center">
					<Loader size="small" />
				</ListItem>
			)}
		</List>
	);

	// Render
	return (
		<div className="chat-list-container">
			<Header allowClose={allowClose} allowResize={allowResize} />
			{isRefreshRequired ? (
				<Notice
					url="x"
					onClose={() => setIsRefreshRequired(false)}
					hasClose
					content="New messages are here. Click to refresh."
					onLinkClick={refreshRooms}
				/>
			) : null}
			{roomSearch && (
				<div className="search-input-container">
					<Input
						value={searchText}
						onChange={(text) => setSearchText(text)}
						placeholder="Search..."
						maxLength={50}
					/>
				</div>
			)}
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					borderBottom: '1px solid rgba(0,0,0,0.1)',
				}}>
				{tabs && (
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<Tooltip title="Ongoing conversations">
							<Button
								sx={{
									color: activeRooms ? theme.palette.primary.main : '#3b3e4d',
									fontWeight: activeRooms ? 600 : 'normal',
									textTransform: 'capitalize',
									ml: 1,
								}}
								variant="text"
								onClick={() => {
									setActiveRooms(true);
								}}>
								Active
							</Button>
						</Tooltip>
						<Tooltip title="Completed conversations">
							<Button
								sx={{
									color: !activeRooms ? theme.palette.primary.main : '#3b3e4d',
									fontWeight: !activeRooms ? 600 : 'normal',
									textTransform: 'capitalize',
								}}
								variant="text"
								onClick={() => {
									setActiveRooms(false);
								}}>
								Past
							</Button>
						</Tooltip>
					</Box>
				)}
				{assignSelfButton && (
					<Tooltip title="Click to assign a waiting user, if any">
						<Button
							className={waitingUsersCount ? 'pulse' : ''}
							sx={{ color: 'rgba(0, 0, 0, 0.54)', textTransform: 'capitalize', m: 1 }}
							variant="outlined"
							onClick={assignSelf}
							startIcon={assignSelfLoading ? <Loader size="small" /> : <PersonIcon />}>
							Waiting&nbsp;&nbsp;
							<Tag className="waiting-count">{waitingUsersCount}</Tag>
						</Button>
					</Tooltip>
				)}
			</Box>
			<div className="chat-list-body-container">
				{/* {isError ? <Fallback type={isError} /> : null} */}
				{!isError && roomsToBeRendered.length ? renderList(roomsToBeRendered) : null}
				{!isError && !roomsToBeRendered.length && isLoading ? renderLoader() : null}
				{searchText && !isError && !roomsToBeRendered.length && !isLoading ? (
					<Fallback type={FallbackType.NO_CHAT_AFTER_SEARCH} />
				) : null}
				{!tabs && !searchText && !isError && !roomsToBeRendered.length && !isLoading ? (
					<Fallback type={FallbackType.NO_CHAT_LIST} />
				) : null}
				{tabs && !searchText && !isError && !roomsToBeRendered.length && !isLoading ? (
					<Fallback
						type={activeRooms ? FallbackType.NO_ACTIVE_CHATS : FallbackType.NO_RECENT_CHATS}
					/>
				) : null}
			</div>
			{allowToasts && (
				<Toast
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...toastProperties}
					onClose={() => setToastProperties({ ...toastProperties, open: false })}
				/>
			)}
		</div>
	);
};

ChatListNew.defaultProps = {
	requireTenantNotification: false,
	assignSelfButton: false,
	roomSearch: false,
	tabs: false,
	allowToasts: false,
	requireChatOpenedListener: false,
	allowClose: false,
	allowResize: false,
};

export default ChatListNew;
