/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Button, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { AxiosError } from 'axios';
import { ApiService } from '../../../services/ApiService';
import { ToastProperties } from '../../../types';
import Toast from '../../Toast/Toast';
import { MESSAGES } from '../../../messages';
import { Loader } from '../../Loader/Loader';

export const AgentsEditModal = (props: {
	open: boolean;
	data: { _id: string; firstName: string; lastName: string };
	onClose: (value: boolean) => void;
}) => {
	// Constants
	const { open, data, onClose } = props;

	// States
	const [toastProperties, setToastProperties] = useState<ToastProperties>({
		open: false,
		isError: false,
		message: '',
	});
	const [isModalLoading, setisModalLoading] = useState<boolean>(false);
	const [editModal, setEditModal] = useState(false);
	const toggleEditModal = () => {
		setEditModal(!editModal);
	};
	const {
		control: editAgentControls,
		handleSubmit: editAgentSubmit,
		formState: { errors: editAgentErrors },
		reset: resetEditAgentsForm,
	} = useForm<{ _id: string; firstName: string; lastName: string; email: string }>();
	const { dirtyFields: editAgentDirtyFields } = useFormState({
		control: editAgentControls,
	});

	// useEffects
	useEffect(() => {
		resetEditAgentsForm({ ...data });
	}, [data, resetEditAgentsForm]);

	// Handlers
	const onFormSubmit = editAgentSubmit(async (modalData) => {
		let dataToBeUpdated = {};
		try {
			setisModalLoading(true);
			if (editAgentDirtyFields.firstName) {
				dataToBeUpdated = { ...dataToBeUpdated, firstName: modalData.firstName };
			}
			if (editAgentDirtyFields.lastName) {
				dataToBeUpdated = { ...dataToBeUpdated, lastName: modalData.lastName };
			}
			if (Object.keys(dataToBeUpdated).length) {
				const response = await ApiService.editAgent({ ...dataToBeUpdated, _id: data._id });
				setToastProperties({
					open: true,
					isError: false,
					message: response.data.message,
				});
			}
			toggleEditModal();
			onClose(true);
		} catch (error) {
			const typedError = error as AxiosError;
			setToastProperties({
				open: true,
				isError: true,
				message: typedError.response?.data?.message || MESSAGES.GENERIC_ERROR,
			});
		} finally {
			setisModalLoading(false);
		}
	});

	// Main
	return (
		<>
			<Dialog open={open}>
				<form onSubmit={onFormSubmit}>
					<DialogTitle
						sx={{
							borderBottom: '1px solid #CBD9E6',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}>
						Edit Agent
						<IconButton onClick={() => onClose(false)}>
							<CloseIcon />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<Box
							sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, pt: { sm: 2 } }}>
							<Controller
								name="firstName"
								control={editAgentControls}
								rules={{ required: true, maxLength: 50 }}
								render={({ field }) => (
									<TextField
										margin="normal"
										required
										fullWidth
										id="firstName"
										label="First Name"
										name="firstName"
										autoComplete="firstName"
										autoFocus
										value={field.value}
										onChange={(em) => field.onChange(em.target.value)}
										error={!!editAgentErrors.firstName?.type || false}
										sx={{ mr: { sm: 2 } }}
										helperText={
											(editAgentErrors.firstName?.type === 'required' &&
												MESSAGES.REQUIRED_FIRST_NAME) ||
											(editAgentErrors.firstName?.type === 'maxLength' &&
												MESSAGES.EXCEEDED_CHARACTERS_50)
										}
									/>
								)}
							/>
							<Controller
								name="lastName"
								control={editAgentControls}
								rules={{ required: true, maxLength: 50 }}
								render={({ field }) => (
									<TextField
										margin="normal"
										required
										fullWidth
										id="lastName"
										label="Last Name"
										name="lastName"
										autoComplete="lastName"
										value={field.value}
										onChange={(em) => field.onChange(em.target.value)}
										error={!!editAgentErrors.lastName?.type || false}
										helperText={
											(editAgentErrors.lastName?.type === 'required' &&
												MESSAGES.REQUIRED_LAST_NAME) ||
											(editAgentErrors.lastName?.type === 'maxLength' &&
												MESSAGES.EXCEEDED_CHARACTERS_50)
										}
									/>
								)}
							/>
						</Box>
					</DialogContent>
					<DialogActions
						sx={{
							borderTop: '1px solid #CBD9E6',
							background: '#F6F7F9',
						}}>
						<Button
							sx={{ textTransform: 'capitalize' }}
							variant="outlined"
							onClick={() => onClose(false)}>
							Cancel
						</Button>
						<Button
							sx={{ textTransform: 'capitalize' }}
							variant="contained"
							type="submit"
							disabled={isModalLoading}>
							{isModalLoading ? <Loader size="small" /> : 'Save'}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
			<Toast
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...toastProperties}
				onClose={() => setToastProperties({ ...toastProperties, open: false })}
			/>
		</>
	);
};
