/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { MessageProps } from '@isc/chatui-core';
import { STATUS } from './enums/status.enum';
import { USER_ROLES } from './enums/user-roles.enum';

export enum MESSAGE_USER_TYPE {
	GUEST = 'GUEST',
	BOT = 'BOT',
	AGENT = 'AGENT',
	SYSTEM = 'SYSTEM',
}

export enum COMMUNICATION_MEDIUMS {
	SOCKET = 'SOCKET',
	API = 'API',
}

export enum ROOM_TYPES {
	PERMANENT = 'PERMANENT',
	LIVE_AGENT = 'LIVE_AGENT',
	DISABLED = 'DISABLED',
}

export enum DEVICE_TYPE {
	DESKTOP = 'DESKTOP',
	MOBILE = 'MOBILE',
	TABLET = 'TABLET',
}

export type CHATTER = MESSAGE_USER_TYPE.AGENT | MESSAGE_USER_TYPE.BOT;

// This will cause clashes if the same deployment is used by multiple clients. Namespace in that case.
export enum STORAGE_KEYS {
	LIVE_AGENT_ROOM = 'live_agent_room',
	SECURITY_ACCESS_TOKEN = 'security_access_token',
	STUDENT_ADVISOR_ACCESS_TOKEN = 'student_advisor_access_token',
	ALL_MESSAGES = 'all_messages',
	LAST_MESSAGE_AT = 'last_message_at',
	USER = 'user',
	TENANT = 'tenant',
	ALL_TENANT_CONFIGS = 'all_tenant_configs',
	ALL_TOKENS = 'all_tokens',
}

/** ***********************************Generic ***************************************** */

export interface GenericError {
	statusCode?: number;
	status?: number;
	message?: string;
}

export interface GenericParams {
	sort_key?: string;
	sort_direction?: string;
	limit?: number;
	page?: number;
}

export interface Config {
	_id: string;
	name: string;
	brandingConfig: {
		avatar?: string;
		title?: string;
		theme: {
			primary: string;
			secondary: string;
			tertiary: string;
			elementFocus?: string;
		};
		iscBranding?: boolean;
		logo?: string;
		favicon: string;
	};
	identifier?: string;
	analyticsDashboardUrl?: string;
}

/* ---------------------------------- Raw Message Data Types --------------------------------- */
// this type will be improved with ISA2-5018
export interface RawMessage {
	_id: string;
	user_type: MESSAGE_USER_TYPE;
	user_id?: string;
	created_at: string;
	room_id: string;
}

export interface RawMessageFromSocket extends RawMessage {
	content: MessageContentText;
	members?: { [key: string]: { is_read: boolean } };
}

export interface RawMessageFromAPIResponse extends RawMessage {
	content: RawMessageContent;
	user: {
		name?: string;
	} | null;
	bot?: {
		name?: string;
	} | null;
}

export interface RawQuickReply {
	value: string;
	display: string;
}

export interface RawCard {
	title: string;
	sub_title: string;
	image: string;
	actions: { url: string; title: string }[];
}

export interface RawMessageContent {
	text?: string;
	card?: RawCard;
	carousel?: RawCard[];
	quick_replies?: RawQuickReply[];
}

export interface RawBotResponse extends GenericResponse {
	data: {
		room_id?: string;
		access_token?: string;
		messages: RawMessageContent[];
		quick_replies: RawQuickReply[];
	};
}

/* ---------------------------------- Parsed Message Data Types --------------------------------- */

export interface QuickReply {
	name: string;
}

export interface ParsedMessage extends MessageProps {
	type: 'text' | 'card' | 'carousel' | 'first';
	user: {
		avatar?: string;
		userId?: string;
		image?: string;
		name?: string | null;
		type: MESSAGE_USER_TYPE;
	};
	content: MessageContentText | MessageContentCard | MessageContentCarousel;
	quickReplies?: QuickReply[];
	hidden?: boolean; // To not show this on the UI
	dateAndTime?: Date | string;
}

export interface MessageContentText {
	text: string;
	quick_replies?: RawQuickReply[];
}

export interface ChatMessageText extends ParsedMessage {
	type: 'text';
	content: MessageContentText;
}

export interface MessageContentCard {
	image?: string;
	title?: string;
	subTitle: string;
	actions: { display: string; link: string }[];
}
export interface ChatMessageCard extends ParsedMessage {
	type: 'card';
	content: MessageContentCard;
}

export interface MessageContentCarousel {
	cards: MessageContentCard[];
}
export interface ChatMessageCarousel extends ParsedMessage {
	type: 'carousel';
	content: MessageContentCarousel;
}

export interface fetchConversationParams extends GenericParams {
	last_message_id?: string;
}

/* ----------------------------------- API ---------------------------------- */
export interface GenericResponse {
	statusCode: number;
	message: string;
	meta?: { total: number };
}

export interface BotMessageRequestBody {
	text: string;
	room_id?: string;
	hidden?: boolean;
}

export interface fetchRoomParams extends GenericParams {
	status?: string;
	search?: string;
	has_ended?: boolean;
}

export interface fetchUserParams extends Omit<GenericParams, 'page'> {
	sort_key?: string;
	sort_direction?: string;
	search?: string | null;
	page: number;
	role?: USER_ROLES;
	status?: STATUS;
}

export interface FetchTenantConfigResponse extends Response {
	data: {
		_id: string;
		name: string;
		branding_config: {
			avatar: string;
			bot_name: string;
			logo: string;
			theme: {
				primary: string;
				secondary: string;
				tertiary: string;
				element_focus?: string;
			};
			isc_branding: boolean;
			favicon: string;
		};
		analytics_dashboard_url?: string;
		identifier?: string;
	};
}

export interface fetchConversationResponse {
	data: RawMessageFromAPIResponse[];
	error?: boolean;
}
/** ********************************************************Socket Events*********************************************** */

export enum SERVER_TRIGGERED_EVENTS {
	MESSAGE = 'message-from-server',
	CLOSE = 'close-from-server',
	WAITING_GUESTS = 'waiting-guests',
}

export enum CLIENT_TRIGGERED_EVENTS {
	JOIN = 'join',
	MESSAGE = 'message-to-server',
}

/** ***********************************************************Room****************************************** */
interface Guest {
	email: string;
	name: string;
	phone: string;
	query: string;
}
export interface Room {
	_id: string;
	last_message: string;
	last_message_at: Date;
	members: RoomMember[];
	ended_at: Date;
	guest_info: Guest;
}

export interface RoomPopulated extends Room {
	members_meta: User[];
}
export interface RoomMember {
	user_id: string;
	unread_count: string;
}
export interface ParsedRoom {
	_id: string;
	title: string;
	lastMessage: string;
	lastMessageAt: Date;
	unreadCount: number | null;
	status?: string;
	imageUrl?: string;
	endedAt?: string | Date;
}

/** ******************************************************************User ****************************************** */
export interface User {
	_id: string;
	first_name: string;
	last_name: string;
	profile_image: string;
	status: string;
	password_reset_required?: boolean;
	tenant_id?: string;
	external_user_identifier?: string;
	email: string;
	role: USER_ROLES;
}
export interface ParsedUser {
	_id: string;
	firstName: string;
	lastName: string;
	email: string;
	externalUserIdentifier?: string;
	role?: USER_ROLES;
	passwordResetRequired?: boolean;
	status?: string;
	tenantId?: string;
}

export interface ToastProperties {
	message?: string;
	isError?: boolean;
	open?: boolean;
}

/* *************************************** Quick Replies **************************************************** */

export interface MessageTemplate {
	_id: string;
	tenant_id: string;
	title: string;
	template: string;
	status: STATUS;
	created_at: Date;
}

export interface TenantConfig {
	tenant_id: string;
	access_token: string;
}

export interface AllTenantConfig {
	token: string;
	tenant_config: Config;
	user_profile: ParsedUser;
}

export interface fetchMessageTemplatesParams {
	sort_key?: string;
	sort_direction?: string;
	search?: string | null;
	page: number;
	limit: number;
}

declare module '@mui/material/styles' {
	interface Theme {
		utils: {
			dark: string;
		};
	}
	// allow configuration using `createTheme`
	interface ThemeOptions {
		utils: {
			dark: string;
		};
	}
}
