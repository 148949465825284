/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import {
	Button,
	Card as ChatUICard,
	CardActions,
	CardMedia,
	CardText,
	CardTitle,
	RichText,
} from '@isc/chatui-core';
import { format } from 'date-fns';
import { Utils } from '../../services/Utils';
import { MessageContentCard, ParsedMessage } from '../../types';
import './Card.css';

type props = {
	content: MessageContentCard;
	size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	isCarousel: boolean;
	user?: ParsedMessage['user'] | null;
	dateAndTime?: ParsedMessage['dateAndTime'] | null;
};

export const Card: React.FC<props> = ({
	content,
	size,
	isCarousel = false,
	user,
	dateAndTime,
}: props): JSX.Element => (
	<ChatUICard size={size} className={isCarousel ? 'carousel-card' : ''}>
		{user?.name && <h4 className="card-sender-name">{Utils.getTrimmedText(user.name, 25)}</h4>}
		{content.image ? <CardMedia image={content.image} /> : null}
		<div className="chat-body">
			<div>
				{content.title ? (
					<CardTitle className={isCarousel ? 'carousel' : ''}>{content.title}</CardTitle>
				) : null}
				<CardText className={isCarousel ? 'carousel' : ''}>
					<RichText content={content.subTitle} />
				</CardText>
			</div>
			<CardActions>
				{content?.actions?.map((action) => (
					<Button onClick={() => Utils.openLink(action.link)} key={action.display} color="primary">
						{action.display}
					</Button>
				))}
			</CardActions>
		</div>
		{dateAndTime && (
			<h4 className="message-time-card">{format(new Date(dateAndTime), 'd MMM, h:mm bbb')}</h4>
		)}
	</ChatUICard>
);

Card.defaultProps = {
	user: null,
	dateAndTime: null,
};
