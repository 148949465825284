/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useEffect } from 'react';
import { PushNotificationService } from '../services/PushNotificationService';

let isKeyInput = true;
const toggleTabIndexForInput = () => {
	isKeyInput = !isKeyInput;
	// eslint-disable-next-line no-unused-expressions
	isKeyInput
		? document.querySelector('.Composer-input')?.removeAttribute('tabindex')
		: document.querySelector('.Composer-input')?.setAttribute('tabindex', '-1');
};

export const useKeyboardShortcuts = (): void => {
	useEffect(() => {
		document
			.querySelector('.Composer-inputTypeBtn')
			?.addEventListener('click', toggleTabIndexForInput);
		window.addEventListener('keydown', PushNotificationService.sendCloseNotification);
		return () => {
			window.removeEventListener('keydown', PushNotificationService.sendCloseNotification);
			document
				.querySelector('.Composer-inputTypeBtn')
				?.removeEventListener('click', toggleTabIndexForInput);
		};
	}, []);
};
