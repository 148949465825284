/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { useEffect, useState } from 'react';

const useDebounce = (value: string | null, delay: number) => {
	const [debounceValue, setDebounceValue] = useState(value);
	useEffect(() => {
		const handler = setTimeout(() => setDebounceValue(value), delay);
		return () => clearTimeout(handler);
	}, [value, delay]);
	return debounceValue;
};

export default useDebounce;
