/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import axios from 'axios';
import { Subject } from 'rxjs';
import { StorageService } from './StorageService';
import { BotMessageRequestBody, RawBotResponse, RawMessageContent } from '../types';

const GPT_URL = process.env.REACT_APP_GPT_URL as string;
if (!GPT_URL) {
	throw new Error('Environment variables are missing.');
}

/**
 * Creates a room on the GPT server.
 * @returns {Promise<{ roomId: string; message: string }>}
 */
const createRoomGPT = async (): Promise<{ roomId: string; message: string }> => {
	const response = await axios({
		method: 'post',
		url: `${GPT_URL}/create_a_room`,
		headers: { 'x-tenant-identifier': StorageService.config.identifier || 'unknown' },
	});
	return {
		roomId: response.data.room_id,
		message: response.data.message,
	};
};

/**
 * Creates a room on the GPT_V2 server.
 * @returns {Promise<{ roomId: string; messages: RawMessageContent[] }>}
 */
const createRoomGPT_V2 = async (): Promise<{ roomId: string; messages: RawMessageContent[] }> => {
	const response = await axios({
		method: 'post',
		url: `${GPT_URL}/create_a_room`,
		headers: { 'x-tenant-identifier': StorageService.config.identifier || 'unknown' },
	});
	return {
		roomId: response?.data?.room_id,
		messages: response?.data?.data?.messages,
	};
};

/**
 * Sends a message to the GPT server.
 * @param message The message to send to the GPT server.
 * @param roomId The room id of the room to send the message to.
 * @param response$ The response subject to send the streaming response to.
 * @returns The "promised" response from the GPT server.
 */
const sendMessage = async (message: string, roomId: string, response$: Subject<string>) => {
	const response = await fetch(`${GPT_URL}/chatbotresponse`, {
		method: 'POST',
		headers: { 'x-tenant-identifier': StorageService.config.identifier || 'unknown' },
		body: JSON.stringify({
			input: message,
			room_id: roomId,
		}),
	});
	if (!response.ok || !response.body) {
		throw response.statusText;
	}

	let fullResponse = '';
	const reader = response.body.getReader();
	const decoder = new TextDecoder();
	const loopRunner = true;

	while (loopRunner) {
		// Here we start reading the stream, until its done.
		// eslint-disable-next-line no-await-in-loop
		const { value, done } = await reader.read();
		if (done) {
			break;
		}
		const decodedChunk = decoder.decode(value, { stream: true });
		fullResponse += decodedChunk;
		response$.next(fullResponse);
	}

	return fullResponse;
};

/**
 * Sends a message to the ChatBotGPT_V2 server.
 * @param body The message object to send to the GPT_V2 server.
 * @returns The "promised" response from the GPT_V2 server.
 */
const sendMessageChatGPT_V2 = async (body: BotMessageRequestBody): Promise<RawBotResponse> => {
	const response = await axios({
		method: 'post',
		headers: { 'x-tenant-identifier': StorageService.config.identifier || 'unknown' },
		url: `${GPT_URL}/chatbotresponse_JSON`,
		data: {
			room_id: body.room_id,
			input: body.text,
		},
	});
	return response.data as RawBotResponse;
};

/**
 * The GPT service, handles all the GPT related requests.
 */
export const GPTService = {
	createRoomGPT,
	createRoomGPT_V2,
	sendMessage,
	sendMessageChatGPT_V2,
};
