/* eslint-disable react/jsx-props-no-spreading */ // spreading does not affect our readability in current case
/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import {
	ChatListPropsForExternalChat,
	ChatListPropsForLiveAgentDashboard,
	ChatWindowPropsForExternalChat,
	ChatWindowPropsForLiveAgentDashboard,
} from '../../configs';
import { Applications } from '../../enums/applications.enum';
import { StorageService } from '../../services/StorageService';
import { ParsedRoom } from '../../types';
import ChatList from '../ChatList/ChatList';
import ChatWindow from '../ChatWindow/ChatWindow';
import { Loader } from '../Loader/Loader';

/**
 * responsible
 * for rendering chatlist and chatwindow with the appropriate props
 * for managing communications between chatlist and chatwindow
 */
export const Chat = () => {
	const [room, setRoom] = useState<ParsedRoom | null>(null);
	const [chatListConfig, setChatListConfig] = useState<object>();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [chatWindowConfig, setChatWindowConfig] = useState<any>();
	const [loading] = useState<boolean>(false);

	useEffect(() => {
		const { application } = StorageService;
		switch (application) {
			case Applications.EXTERNAL_CHAT:
				setChatListConfig(ChatListPropsForExternalChat);
				setChatWindowConfig(ChatWindowPropsForExternalChat);
				break;
			case Applications.LIVE_AGENT_DASHBOARD:
				setChatListConfig(ChatListPropsForLiveAgentDashboard);
				setChatWindowConfig(ChatWindowPropsForLiveAgentDashboard);
				break;
			default:
				throw Error('invalid application type');
		}
	}, []);

	const handleActionsOnHeader = (action: string) => {
		switch (action) {
			case 'CLOSE_WIDGET':
				// UIService.toggleChatWindow();
				setRoom(null);
				break;
			default:
				break;
		}
	};

	return (
		<>
			{chatListConfig && chatWindowConfig && !loading && (
				<Box
					id="chat-wrapper"
					sx={{
						display: 'flex',
						flexDirection: 'row',
						height:
							StorageService.application === Applications.LIVE_AGENT_DASHBOARD ? '80vh' : '100vh',
					}}>
					<ChatList
						onRoomSelect={(selectedRoom) => setRoom(selectedRoom)}
						currentRoom={room}
						{...chatListConfig}
					/>{' '}
					<ChatWindow
						room={room}
						handleActionsOnHeader={handleActionsOnHeader}
						{...chatWindowConfig}
					/>
				</Box>
			)}
			{loading && <Loader size="regular" />}
		</>
	);
};
