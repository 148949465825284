/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import {
	Box,
	createTheme,
	CssBaseline,
	Grid,
	Paper,
	TextField,
	ThemeProvider,
	Typography,
	Button,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiService } from '../../../services/ApiService';
import Toast from '../../Toast/Toast';
import { ToastProperties } from '../../../types';
import { MESSAGES } from '../../../messages';
import { LoggerService } from '../../../services/LoggerService';
import { StorageService } from '../../../services/StorageService';

export const ResetPassword = () => {
	// States
	const navigate = useNavigate();
	const params = useParams();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const {
		control,
		handleSubmit,
		formState: { errors },
		getValues,
	} = useForm<{ password: string; passwordConfirm: string }>({
		defaultValues: {
			password: '',
			passwordConfirm: '',
		},
	});
	const [toastProperties, setToastProperties] = useState<ToastProperties>({
		open: false,
		isError: false,
		message: '',
	});
	const theme = createTheme();

	useEffect(() => {
		const verifyToken = async () => {
			const resetToken = params?.resetToken;
			if (resetToken) {
				// Validate
				const valid = await ApiService.validateResetPasswordToken(resetToken);
				if (!valid) {
					setToastProperties({
						message:
							'This link is invalid or has expired. Please go back to login page to raise a new forgot password request.',
						open: true,
						isError: true,
					});
				}
			}
		};
		verifyToken();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// API calls
	const resetPassword = handleSubmit(async (data) => {
		try {
			setIsLoading(true);
			const response = await ApiService.resetPassword(
				data.password,
				StorageService.accessToken as string,
				params?.resetToken,
			);
			setToastProperties({ message: response.data.message, open: true, isError: false });
			const tokens = StorageService.allAccessTokens;
			if (params?.resetToken) {
				navigate('/login');
			} else {
				const userProfiles = await Promise.all(
					tokens.map(async (token) => ApiService.fetchUserInfo(token)),
				);
				const userTenants = await Promise.all(
					tokens.map(async (token) => ApiService.fetchTenantConfig(true, token)),
				);
				const allTenantConfigs = tokens.map((token, idx) => ({
					token,
					tenant_config: userTenants[idx],
					user_profile: userProfiles[idx],
				}));
				StorageService.allTenantConfigs = allTenantConfigs;
				navigate('/');
			}
		} catch (error) {
			setToastProperties({ message: MESSAGES.GENERIC_ERROR, open: true, isError: true });
			LoggerService.logError(error, 'forgot password error');
		} finally {
			setIsLoading(false);
		}
	});

	return (
		<ThemeProvider theme={theme}>
			<Grid container component="main" sx={{ height: '100vh' }}>
				<CssBaseline />
				<Grid
					item
					xs={false}
					sm={4}
					md={7}
					sx={{
						backgroundImage: 'url(https://cdn.ischoolconnect.com/chatbot/live-agent-welcome.svg)',
						backgroundRepeat: 'no-repeat',
						backgroundColor: (t) =>
							t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
						backgroundSize: 'cover',
						backgroundPosition: 'center',
					}}
				/>
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}>
						<Typography component="h1" variant="h5">
							Reset Password
						</Typography>
						<Box component="form" noValidate onSubmit={resetPassword} sx={{ mt: 1 }}>
							<Controller
								name="password"
								control={control}
								rules={{ required: true, minLength: 8, maxLength: 30 }}
								render={({ field }) => (
									<TextField
										margin="normal"
										required
										fullWidth
										name="password"
										label="Password"
										type="password"
										id="password"
										autoFocus
										value={field.value}
										onChange={(pass) => field.onChange(pass.target.value)}
										error={!!errors.password?.type || false}
										helperText={
											(errors.password?.type === 'required' && 'This is required') ||
											(errors.password?.type === 'minLength' && 'Min characters are 8') ||
											(errors.password?.type === 'maxLength' && 'Max characters are 30')
										}
									/>
								)}
							/>
							<Controller
								name="passwordConfirm"
								control={control}
								rules={{
									required: true,
									minLength: 8,
									maxLength: 30,
									validate: {
										passwordMatch: (v) => {
											const { password } = getValues();
											return v === password;
										},
									},
								}}
								render={({ field }) => (
									<TextField
										margin="normal"
										required
										fullWidth
										name="passwordConfirm"
										label="Re-enter Password"
										type="password"
										id="passwordConfirm"
										value={field.value}
										onChange={(pass) => field.onChange(pass.target.value)}
										error={!!errors.passwordConfirm?.type || false}
										helperText={
											(errors.passwordConfirm?.type === 'required' && 'This is required') ||
											(errors.passwordConfirm?.type === 'minLength' && 'Min characters are 8') ||
											(errors.passwordConfirm?.type === 'maxLength' && 'Max characters are 30') ||
											(errors.passwordConfirm?.type === 'passwordMatch' && 'Passwords should match')
										}
									/>
								)}
							/>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 2, textTransform: 'capitalize', background: '#00AFF0' }}
								disabled={isLoading}>
								{isLoading ? 'Loading...' : 'Reset Password'}
							</Button>
						</Box>
					</Box>
				</Grid>
				<Toast
					open={toastProperties.open}
					isError={toastProperties.isError}
					message={toastProperties.message}
					onClose={() => setToastProperties({ ...toastProperties, open: false })}
				/>
			</Grid>
		</ThemeProvider>
	);
};
