/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { Backdrop, Box, Divider, Drawer, List, ListItem, useTheme } from '@mui/material';
import { STATUS } from '../../enums/status.enum';
import { AllTenantConfig } from '../../types';
import AccountIcon from '../AccountIcons/AccountIcon';

interface Props {
	accounts: AllTenantConfig[];
	updateUnreadCountOnDrawerMenu: (cnt: number, isRead?: boolean) => void;
	handleDrawer: () => void;
	mobileOpen: boolean;
}

const AccountDrawer = ({
	accounts,
	handleDrawer,
	updateUnreadCountOnDrawerMenu,
	mobileOpen,
}: Props) => {
	const appTheme = useTheme();
	return (
		<>
			<Backdrop
				sx={{
					display: { md: 'none' },
					color: '#fff',
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={mobileOpen}
				onClick={() => handleDrawer()}
			/>
			<Drawer
				variant="permanent"
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						bgcolor: appTheme.utils.dark,
						top: { xs: '0', md: '9%' },
						width: { xs: 240, md: '98px' },
						zIndex: '1202',
						display: { xs: mobileOpen ? 'block' : 'none', md: 'block' },
					},
				}}>
				<Box
					sx={{
						textAlign: 'center',
					}}>
					<Divider />
					<List sx={{ '& .MuiListItemButton-root': { width: '100%' } }}>
						{accounts.map((account) => (
							<ListItem
								sx={{ color: '#4C4C4C', flexDirection: 'column' }}
								disablePadding
								disabled={account.user_profile.status === STATUS.DISABLED}>
								<AccountIcon
									account={account}
									updateUnreadCountOnDrawerMenu={updateUnreadCountOnDrawerMenu}
									handleDrawer={handleDrawer}
								/>
							</ListItem>
						))}
					</List>
				</Box>
			</Drawer>
		</>
	);
};
export default AccountDrawer;
