/* eslint-disable @typescript-eslint/no-use-before-define */
// TODO: Refactor this entire file. When the above line(1) is removed,
// there are eslint errors related to structure of this file
// Refactor it in a way that does not impact the functionality
/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { Subject } from 'rxjs';
import { AllTenantConfig, ParsedMessage, ParsedUser, STORAGE_KEYS, Config } from '../types';
import { LoggerService } from './LoggerService';
import { CONFIG } from '../configs';
import { Applications } from '../enums/applications.enum';

let _config: Config;
let _application: Applications;
let _roomId: string | null;
let _accessToken: string | null;
let _allTenantConfigs: AllTenantConfig[];
let _allAccessTokens: string[] = [];
let _authTenant: Config;
const isStorageEnabled = (): boolean => {
	let hasAccess = false;
	try {
		localStorage.setItem('test', 'test');
		localStorage.removeItem('test');
		hasAccess = true;
	} catch (error) {
		hasAccess = false;
	}
	return hasAccess;
};
let _tenantApiKey = '';
let allMessages: ParsedMessage[] = [];
const allMessages$ = new Subject<ParsedMessage[]>();
let _authUser: ParsedUser;

const getLastMessageAt = (): Date | null => {
	const stringDate = StorageService.getFromLocalStorage(STORAGE_KEYS.LAST_MESSAGE_AT);
	if (stringDate && !Number.isNaN(Date.parse(stringDate))) {
		return new Date(stringDate as string);
	}
	return null;
};

const clearMessages = (): void => {
	allMessages = [];
	StorageService.removeFromLocalStorage(STORAGE_KEYS.ALL_MESSAGES);
	StorageService.removeFromLocalStorage(STORAGE_KEYS.LAST_MESSAGE_AT);
	StorageService.removeFromLocalStorage(STORAGE_KEYS.LIVE_AGENT_ROOM);
};

const restoreMessages = (): void => {
	try {
		const stringMessages = StorageService.getFromLocalStorage(STORAGE_KEYS.ALL_MESSAGES);
		if (stringMessages) {
			allMessages = JSON.parse(stringMessages);
		}
	} catch (error) {
		// The localStorage was tweaked, most probably. Throw it away. No need to log to our servers.
		// Logging added temporarily.
		LoggerService.logError(error);
		clearMessages();
	}
};

const storeMessage = (message: ParsedMessage): void => {
	allMessages.push(message);
	StorageService.setInLocalStorage(STORAGE_KEYS.ALL_MESSAGES, JSON.stringify(allMessages));
	StorageService.setInLocalStorage(STORAGE_KEYS.LAST_MESSAGE_AT, new Date().toISOString());
};

const checkHistory = (): void => {
	const lastMessageAt = getLastMessageAt();
	if (lastMessageAt) {
		const cutOffTime = new Date(
			Date.parse(new Date().toISOString()) - CONFIG.CUT_OFF_DAYS * 24 * 60 * 60 * 1000,
		);
		if (lastMessageAt > cutOffTime) {
			restoreMessages();
		} else {
			clearMessages();
		}
	}
};

const clearAuthData = () => {
	if (StorageService.application === Applications.LIVE_AGENT_DASHBOARD) {
		StorageService.allTenantConfigs = null;
	}
	sessionStorage.clear();
	localStorage.clear();
	_accessToken = null;
	_allTenantConfigs = [];
};

if (isStorageEnabled()) {
	window.addEventListener('storage', (e) => {
		if (e.key === STORAGE_KEYS.ALL_MESSAGES) {
			const stringMessages = StorageService.getFromLocalStorage(STORAGE_KEYS.ALL_MESSAGES);
			if (stringMessages) {
				const storageMessages = JSON.parse(stringMessages);
				if (storageMessages.length !== allMessages.length) {
					allMessages = storageMessages;
					allMessages$.next(allMessages);
				}
			}
		}
		if (
			StorageService.application === Applications.LIVE_AGENT_DASHBOARD &&
			e.key === `${_application}-${_tenantApiKey}-${STORAGE_KEYS.ALL_TENANT_CONFIGS}` &&
			e.newValue === null
		) {
			sessionStorage.clear();
			// eslint-disable-next-line no-restricted-globals
			location.href = '/login';
		}
		if (e.key === STORAGE_KEYS.LIVE_AGENT_ROOM || e.key === STORAGE_KEYS.SECURITY_ACCESS_TOKEN) {
			// ChatService.checkActiveAgentConnection();
		}
	});
}

export const StorageService = {
	get config(): Config {
		return _config;
	},
	set config(value: Config) {
		_config = value;
	},
	get application(): Applications {
		return _application;
	},
	set application(app: Applications) {
		_application = app;
	},
	get roomId(): string | null {
		if (!_roomId) {
			_roomId = this.getFromLocalStorage(STORAGE_KEYS.LIVE_AGENT_ROOM);
		}
		return _roomId;
	},
	set roomId(id: string | null) {
		_roomId = id;
		if (id) {
			this.setInLocalStorage(STORAGE_KEYS.LIVE_AGENT_ROOM, id);
		} else {
			this.removeFromLocalStorage(STORAGE_KEYS.LIVE_AGENT_ROOM);
		}
	},
	get accessToken(): string | null {
		if (!_accessToken) {
			_accessToken =
				StorageService.application === Applications.LIVE_AGENT_DASHBOARD
					? this.getFromSessionStorage(STORAGE_KEYS.SECURITY_ACCESS_TOKEN)
					: this.getFromLocalStorage(STORAGE_KEYS.SECURITY_ACCESS_TOKEN);
		}
		return _accessToken;
	},
	set accessToken(id: string | null) {
		// only for live agent dashboard, we require token in session, for rest, we require it to persist in local storage
		if (id) {
			// eslint-disable-next-line no-unused-expressions
			StorageService.application === Applications.LIVE_AGENT_DASHBOARD
				? this.setInSessionStorage(STORAGE_KEYS.SECURITY_ACCESS_TOKEN, id)
				: this.setInLocalStorage(STORAGE_KEYS.SECURITY_ACCESS_TOKEN, id);
		} else {
			// eslint-disable-next-line no-unused-expressions
			StorageService.application === Applications.LIVE_AGENT_DASHBOARD
				? this.removeFromSessionStorage(STORAGE_KEYS.SECURITY_ACCESS_TOKEN)
				: this.removeFromLocalStorage(STORAGE_KEYS.SECURITY_ACCESS_TOKEN);
		}
		_accessToken = id;
	},
	get authUser(): ParsedUser {
		if (!_authUser && StorageService.application === Applications.LIVE_AGENT_DASHBOARD) {
			const user = this.getFromSessionStorage(STORAGE_KEYS.USER);
			if (user) {
				_authUser = JSON.parse(user);
			}
		}
		return _authUser;
	},
	set authUser(user: ParsedUser) {
		// For student advisor chat, we always update fresh data and do fresh api call for user information. So, in that case, user data should not persist. Hence, this data persists only until reload
		// For chatbot, we don't use this local storage variable
		// For live agent dashboard, we require this only in session storage
		if (StorageService.application === Applications.LIVE_AGENT_DASHBOARD) {
			this.setInSessionStorage(STORAGE_KEYS.USER, JSON.stringify(user));
		}
		_authUser = user;
	},
	get authTenant(): Config {
		if (!_authTenant && StorageService.application === Applications.LIVE_AGENT_DASHBOARD) {
			const tenant = this.getFromSessionStorage(STORAGE_KEYS.TENANT);
			if (tenant) {
				_authTenant = JSON.parse(tenant);
			}
		}
		return _authTenant;
	},
	set authTenant(tenant: Config) {
		// For chatbot and student advisor chat, we don't use this local storage variable.
		// For live agent dashboard, we require this only in session storage
		if (StorageService.application === Applications.LIVE_AGENT_DASHBOARD) {
			this.setInSessionStorage(STORAGE_KEYS.TENANT, JSON.stringify(tenant));
		}
		_authTenant = tenant;
	},
	get tenantApiKey(): string {
		return _tenantApiKey ?? '';
	},
	set tenantApiKey(apiKey: string) {
		_tenantApiKey = apiKey;
	},
	get allTenantConfigs(): AllTenantConfig[] {
		if (!_allTenantConfigs) {
			_allTenantConfigs = JSON.parse(
				this.getFromLocalStorage(STORAGE_KEYS.ALL_TENANT_CONFIGS) as string,
			) as unknown as AllTenantConfig[];
		}
		return _allTenantConfigs;
	},
	set allTenantConfigs(data: AllTenantConfig[] | null) {
		// eslint-disable-next-line no-unused-expressions
		data
			? this.setInLocalStorage(STORAGE_KEYS.ALL_TENANT_CONFIGS, JSON.stringify(data))
			: this.removeFromLocalStorage(STORAGE_KEYS.ALL_TENANT_CONFIGS);
		_allTenantConfigs = data ?? [];
	},
	get allAccessTokens() {
		return (
			_allAccessTokens || JSON.parse(this.getFromLocalStorage(STORAGE_KEYS.ALL_TOKENS) ?? '[]')
		);
	},
	set allAccessTokens(tokens: string[]) {
		_allAccessTokens = tokens;
		this.setInLocalStorage(STORAGE_KEYS.ALL_TOKENS, JSON.stringify(tokens));
	},
	allMessages$,
	isStorageEnabled,
	allMessages: () => allMessages,
	storeMessage,
	checkHistory,
	getFromLocalStorage: (key: STORAGE_KEYS): string | null => {
		try {
			return localStorage.getItem(`${_application}-${_tenantApiKey}-${key}`);
		} catch (error) {
			return null;
		}
	},
	setInLocalStorage: (key: STORAGE_KEYS, value: string): void => {
		localStorage.setItem(`${_application}-${_tenantApiKey}-${key}`, value);
	},
	removeFromLocalStorage: (key: STORAGE_KEYS): void => {
		localStorage.removeItem(`${_application}-${_tenantApiKey}-${key}`);
	},
	getFromSessionStorage: (key: STORAGE_KEYS): string | null => {
		try {
			return sessionStorage.getItem(`${_application}-${_tenantApiKey}-${key}`);
		} catch (error) {
			return null;
		}
	},
	setInSessionStorage: (key: STORAGE_KEYS, value: string): void => {
		sessionStorage.setItem(`${_application}-${_tenantApiKey}-${key}`, value);
	},
	removeFromSessionStorage: (key: STORAGE_KEYS) => {
		sessionStorage.removeItem(`${_application}-${_tenantApiKey}-${key}`);
	},
	clearAuthData,
};
