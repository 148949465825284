/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { FC, ReactElement } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { STATUS } from '../enums/status.enum';
import { StorageService } from '../services/StorageService';

interface props {
	children: ReactElement;
}

// login -> authentication -> reset - password -> guard -> route -> authorization -> child route
// login -> authentication -> route -> authorization -> child route
const AuthenticatedRoute: FC<props> = ({ children }: props) => {
	const { allTenantConfigs } = StorageService;
	const { id: tenantId } = useParams();
	let elementToBeRendered = null;
	// only one tenant, then disabled message case.
	if (allTenantConfigs?.length) {
		const currentUserConfig = allTenantConfigs.find(
			(config) => config.tenant_config._id === tenantId,
		);
		if (currentUserConfig) {
			StorageService.accessToken = currentUserConfig.token;
			StorageService.authUser = currentUserConfig.user_profile;
			StorageService.authTenant = currentUserConfig.tenant_config;
			elementToBeRendered = children;
		}
		if (
			!tenantId ||
			!currentUserConfig ||
			currentUserConfig.user_profile.status === STATUS.DISABLED
		) {
			allTenantConfigs.every((config) => {
				if (config.user_profile.status === STATUS.ACTIVE) {
					elementToBeRendered = <Navigate to={`/${config.tenant_config._id}`} />;
					return false;
				}
				return true;
			});
		}
	}
	if (!elementToBeRendered) {
		localStorage.clear();
		StorageService.clearAuthData();
	}
	return elementToBeRendered || <Navigate to="/login" />;
};

export default AuthenticatedRoute;
