/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { STATUS } from '../../../enums/status.enum';
import { MESSAGES } from '../../../messages';
import { ApiService } from '../../../services/ApiService';
import { ToastProperties } from '../../../types';
import { Loader } from '../../Loader/Loader';
import Toast from '../../Toast/Toast';

interface StatusChangeConfirmationModalProps {
	open: boolean;
	onClose: (success: boolean) => void;
	modalData: {
		id: string;
		status: STATUS;
	};
}

const StatusChangeConfirmationModal = ({
	open,
	onClose,
	modalData,
}: StatusChangeConfirmationModalProps) => {
	const [toastProperties, setToastProperties] = useState<ToastProperties>({
		open: false,
		isError: false,
		message: '',
	});
	const [isModalLoading, setisModalLoading] = useState<boolean>(false);

	const updateAgentStatus = async (id: string, disable: boolean) => {
		try {
			setisModalLoading(true);
			const response = await ApiService.editAgent({
				_id: id,
				status: disable ? STATUS.DISABLED : STATUS.ACTIVE,
			});
			setToastProperties({
				open: true,
				isError: false,
				message: response.data.message,
			});
			onClose(true);
		} catch (error) {
			const typedError = error as AxiosError;
			setToastProperties({
				open: true,
				isError: true,
				message: typedError.response?.data?.message || MESSAGES.GENERIC_ERROR,
			});
		} finally {
			setisModalLoading(false);
		}
	};
	return (
		<Box sx={{ p: 3, pb: 1 }}>
			<Dialog open={open} onClose={() => onClose(false)}>
				<DialogContent sx={{ fontSize: 16 }}>
					Are you sure you want to {modalData.status === STATUS.ACTIVE ? 'disable' : 'enable'} this
					agent?
					{modalData.status === STATUS.ACTIVE && (
						<Typography
							variant="subtitle1"
							sx={{ color: '#8189A9', pt: { xs: 1, sm: 1 }, fontSize: 12 }}>
							Note: Once disabled, the agent will not be able to login.
						</Typography>
					)}
				</DialogContent>
				<DialogActions
					sx={{
						p: 1.5,
						borderTop: '1px solid #CBD9E6',
						background: '#F6F7F9',
					}}>
					<Button
						variant="outlined"
						sx={{ textTransform: 'capitalize' }}
						onClick={() => onClose(false)}>
						No
					</Button>
					<Button
						sx={{ textTransform: 'capitalize' }}
						variant="contained"
						onClick={() => {
							updateAgentStatus(modalData.id, modalData.status === STATUS.ACTIVE);
						}}
						disabled={isModalLoading}>
						{isModalLoading ? <Loader size="small" /> : 'Yes'}
					</Button>
				</DialogActions>
			</Dialog>
			<Toast
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...toastProperties}
				onClose={() => setToastProperties({ ...toastProperties, open: false })}
			/>
		</Box>
	);
};

export default StatusChangeConfirmationModal;
