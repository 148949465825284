/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { Config } from '../types';

const setTheme = (config: Config): void => {
	document.documentElement.style.setProperty('--brand-1', config.brandingConfig.theme.primary);
	document.documentElement.style.setProperty('--brand-2', config.brandingConfig.theme.secondary);
	document.documentElement.style.setProperty('--brand-3', config.brandingConfig.theme.tertiary);
	if (config.brandingConfig.theme.elementFocus)
		document.documentElement.style.setProperty(
			'--element-focus-color',
			config.brandingConfig.theme.elementFocus,
		);
};

/**
 * adds a class on chat window component that displays it ( only works on mobile view or screen width less than 991px)
 */
const toggleChatWindow = (): void => {
	document.querySelector('.chat-window-container')?.classList.toggle('hide-chat-window');
};

const cssThemingOverridesForLiveAgent = () => {
	document.documentElement.style.setProperty('--other-user-message-bubble-backgrond', '#F6F6F6');
	document.documentElement.style.setProperty('--header-background-color', 'var(--white)');
	document.documentElement.style.setProperty('--header-font-color', 'var(--black)');
	document.documentElement.style.setProperty('--chat-background-color', 'var(--white)');
	document.documentElement.style.setProperty('--app-background-color', '#f2f2f2');
	document.documentElement.style.setProperty('--card-background-color', '#f2f0fe');
};

export const UIService = {
	setTheme,
	toggleChatWindow,
	cssThemingOverridesForLiveAgent,
};
