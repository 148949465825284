/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { ApiService } from '../../../services/ApiService';
import { ToastProperties } from '../../../types';
import Toast from '../../Toast/Toast';
import { MESSAGES } from '../../../messages';
import { Loader } from '../../Loader/Loader';
import { emailRegex } from '../../../configs';

export const AgentsAddModal = (props: { open: boolean; onClose: (success: boolean) => void }) => {
	// Constants
	const { open, onClose } = props;

	// States
	const [toastProperties, setToastProperties] = useState<ToastProperties>({
		open: false,
		isError: false,
		message: '',
	});
	const [isModalLoading, setisModalLoading] = useState<boolean>(false);
	const {
		control: addAgentControls,
		handleSubmit: addAgentSubmit,
		formState: { errors: addAgentErrors },
		reset: resetAddAgentsForm,
	} = useForm<{ firstName: string; lastName: string; email: string }>({
		defaultValues: { firstName: '', lastName: '', email: '' },
	});

	// Handlers
	const closeModal = (value = false) => {
		resetAddAgentsForm();
		onClose(value);
	};
	const onFormSubmit = addAgentSubmit(async (data) => {
		let apiSuccess = false;
		try {
			setisModalLoading(true);
			const response = await ApiService.addAgent(data);
			setToastProperties({ open: true, message: response.data.message, isError: false });
			apiSuccess = true;
			resetAddAgentsForm();
			closeModal(apiSuccess);
		} catch (error) {
			const typedError = error as AxiosError;
			setToastProperties({
				open: true,
				message: typedError.response?.data.message || MESSAGES.GENERIC_ERROR,
				isError: true,
			});
			apiSuccess = false;
		} finally {
			setisModalLoading(false);
		}
	});

	return (
		<>
			<Dialog open={open} onClose={() => closeModal()}>
				<form onSubmit={onFormSubmit} noValidate>
					<DialogTitle
						sx={{
							borderBottom: '1px solid #CBD9E6',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}>
						Add Agent
						<IconButton onClick={() => closeModal()}>
							<CloseIcon />
						</IconButton>
					</DialogTitle>
					<DialogContent
						sx={{
							background: '#F9FAFB',
						}}>
						<Box
							sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, pt: { sm: 2 } }}>
							<Controller
								name="firstName"
								control={addAgentControls}
								rules={{ required: true, maxLength: 50 }}
								render={({ field }) => (
									<TextField
										margin="normal"
										required
										fullWidth
										id="firstName"
										label="First Name"
										name="firstName"
										autoComplete="firstName"
										autoFocus
										value={field.value}
										onChange={(em) => field.onChange(em.target.value)}
										error={!!addAgentErrors.firstName?.type || false}
										sx={{ mr: { sm: 2 } }}
										helperText={
											(addAgentErrors.firstName?.type === 'required' &&
												MESSAGES.REQUIRED_FIRST_NAME) ||
											(addAgentErrors.firstName?.type === 'maxLength' &&
												MESSAGES.EXCEEDED_CHARACTERS_50)
										}
									/>
								)}
							/>
							<Controller
								name="lastName"
								control={addAgentControls}
								rules={{ required: true, maxLength: 50 }}
								render={({ field }) => (
									<TextField
										margin="normal"
										required
										fullWidth
										id="lastName"
										label="Last Name"
										name="lastName"
										autoComplete="lastName"
										value={field.value}
										onChange={(em) => field.onChange(em.target.value)}
										error={!!addAgentErrors.lastName?.type || false}
										helperText={
											(addAgentErrors.lastName?.type === 'required' &&
												MESSAGES.REQUIRED_LAST_NAME) ||
											(addAgentErrors.lastName?.type === 'maxLength' &&
												MESSAGES.EXCEEDED_CHARACTERS_50)
										}
									/>
								)}
							/>
						</Box>
						<Controller
							name="email"
							control={addAgentControls}
							rules={{ required: true, pattern: emailRegex, maxLength: 191 }}
							render={({ field }) => (
								<TextField
									margin="normal"
									required
									fullWidth
									id="email"
									label="Email Address"
									name="email"
									autoComplete="email"
									value={field.value}
									onChange={(em) => field.onChange(em.target.value)}
									error={!!addAgentErrors.email?.type || false}
									helperText={
										(addAgentErrors.email?.type === 'required' && 'Email is required') ||
										(addAgentErrors.email?.type === 'pattern' && MESSAGES.INVALID_EMAIL) ||
										(addAgentErrors.email?.type === 'maxLength' && MESSAGES.EXCEEDED_CHARACTERS)
									}
								/>
							)}
						/>
						<Typography
							variant="subtitle1"
							sx={{ color: '#8189A9', pt: { xs: 1, sm: 3 }, pb: { sm: 1 } }}>
							Note: On adding a new user, an email will be sent to set up their account.
						</Typography>
					</DialogContent>
					<DialogActions
						sx={{
							borderTop: '1px solid #CBD9E6',
							background: '#F6F7F9',
						}}>
						<Button
							variant="outlined"
							sx={{ textTransform: 'capitalize' }}
							onClick={() => closeModal()}>
							Cancel
						</Button>
						<Button
							sx={{ textTransform: 'capitalize' }}
							variant="contained"
							type="submit"
							disabled={isModalLoading}>
							{isModalLoading ? <Loader size="small" /> : 'Save'}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
			<Toast
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...toastProperties}
				onClose={() => setToastProperties({ ...toastProperties, open: false })}
			/>
		</>
	);
};
