/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import {
	Avatar,
	Badge,
	IconButton,
	keyframes,
	ListItemButton,
	ListItemText,
	Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Subscription } from 'rxjs';
import { STATUS } from '../../enums/status.enum';
import { MESSAGES } from '../../messages';
import { PushNotificationService } from '../../services/PushNotificationService';
import { SfxService } from '../../services/SfxService';
import { StorageService } from '../../services/StorageService';
import { TenantNotificationService } from '../../services/TenantNotificationService';
import { UserNotificationService } from '../../services/UserNotificationService';
import { Utils } from '../../services/Utils';
import { AllTenantConfig } from '../../types';

interface Props {
	account: AllTenantConfig;
	updateUnreadCountOnDrawerMenu: (cnt: number, isRead?: boolean) => void;
	handleDrawer: () => void;
}
const AccountIcon = ({ account, updateUnreadCountOnDrawerMenu, handleDrawer }: Props) => {
	const [unreadCountOnIcon, setUnreadCountOnIcon] = useState(0);
	const [waitingUsersCount, setWaitingUsersCount] = useState(0);
	const [isActiveAccount, setIsActiveAccount] = useState(false);
	const navigate = useNavigate();
	const wave = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

	const accStyle = isActiveAccount
		? { border: '2px solid #17234E', boxShadow: '0px 0px 0px 2px #FFFFFF' }
		: {};

	const tenantClickHandler = () => {
		updateUnreadCountOnDrawerMenu(unreadCountOnIcon, true);
		setUnreadCountOnIcon(0);
		navigate(`/${account.tenant_config._id}`);
		handleDrawer();
	};

	const getLatestActiveAccount = () => {
		let latestActiveAccount;
		setIsActiveAccount((currentValue) => {
			latestActiveAccount = currentValue;
			return latestActiveAccount;
		});
		return latestActiveAccount;
	};

	useEffect(() => {
		let userNotification$: Subscription;
		let userNotificationService: UserNotificationService;
		let tenantNotification$: Subscription;
		let tenantNotificationService: TenantNotificationService;

		if (account.user_profile.status !== STATUS.DISABLED) {
			userNotificationService = new UserNotificationService(account.token);
			userNotificationService.init();
			const notificationData = { text: '' };
			userNotification$ = userNotificationService.notifications$.subscribe(async (notification) => {
				if (notification.members && !getLatestActiveAccount()) {
					notificationData.text = notification.lastMessage;
					setUnreadCountOnIcon((count) => count + 1);
					updateUnreadCountOnDrawerMenu(unreadCountOnIcon + 1);
					PushNotificationService.sendUnreadMessageNotification(notificationData);
				}
			});

			tenantNotificationService = new TenantNotificationService(account.token);
			tenantNotificationService.init();
			tenantNotification$ = tenantNotificationService.notifications$.subscribe(
				(data: { count: number }) => {
					if (data) {
						setWaitingUsersCount(data.count);
						if (data.count) {
							PushNotificationService.sendUnreadMessageNotification({
								title: '',
								text: MESSAGES.WAITING_USERS,
							});
							SfxService.playWaitingUserSound();
						} else {
							SfxService.stopWaitingUserSound();
						}
					}
				},
			);
		}
		return () => {
			userNotification$?.unsubscribe();
			userNotificationService?.close();
			tenantNotification$?.unsubscribe();
			tenantNotificationService?.close();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (account.tenant_config._id === StorageService.authTenant._id) {
			setIsActiveAccount(true);
			setUnreadCountOnIcon(0);
		}

		return () => {
			setIsActiveAccount(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [StorageService.authTenant]);

	return (
		<ListItemButton
			sx={{
				textAlign: 'left',
				'& .MuiAvatar-root': { padding: '5px' },
			}}>
			<Tooltip
				title={
					account.user_profile.status === STATUS.DISABLED
						? MESSAGES.DISABLED_USER_ACCOUNT
						: account.tenant_config.name
				}>
				<IconButton
					onClick={tenantClickHandler}
					sx={{
						textAlign: 'left',
						'& .MuiAvatar-root': { padding: '5px' },
					}}>
					<Badge
						sx={{ '& .MuiBadge-badge': { backgroundColor: '#4FC766' } }}
						color="primary"
						overlap="circular"
						badgeContent={unreadCountOnIcon}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}>
						<Badge
							sx={{
								'& .MuiBadge-badge': {
									backgroundColor: '#FF0000',
									animation: `${wave} 1s infinite `,
								},
							}}
							color="primary"
							overlap="circular"
							badgeContent={waitingUsersCount}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}>
							<Avatar
								sx={{
									bgcolor: '#fff',
									width: 48,
									height: 48,
									borderRadius: '16px',
									...accStyle,
								}}
								variant="square"
								src={account.tenant_config.brandingConfig.favicon}
							/>
						</Badge>
					</Badge>
				</IconButton>
			</Tooltip>
			<ListItemText
				sx={{
					ml: 2,
					color: '#fff',
					'& .MuiTypography-root': { color: '#fff', fontSize: '14px', fontWeight: '700' },
					'& .MuiListItemText-secondary': { fontSize: '12px', fontWeight: '400' },
					display: { xs: 'block', md: 'none' },
				}}
				primary={account.tenant_config?.name}
				secondary={Utils.capitalize(account.user_profile?.role)}
			/>
		</ListItemButton>
	);
};

export default AccountIcon;
