/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
export const mainMenuQuickReply = [
	{
		name: 'Main Menu',
	},
];
export const exitQuickReply = [
	{
		name: 'Exit',
	},
];
