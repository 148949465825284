import { Applications } from '../enums/applications.enum';
import { StorageService } from './StorageService';

/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
let notificationPermission!: string;
let tabHidden!: boolean;

const getVisibilityChangeBrowserSupportDetails = () => {
	let hidden;
	let visibilityChange;
	const typedDocument: Document & { msHidden?: boolean; webkitHidden?: boolean } = document;
	if (typeof typedDocument.hidden !== 'undefined') {
		// Opera 12.10 and Firefox 18 and later support
		hidden = 'hidden';
		visibilityChange = 'visibilitychange';
	} else if (typeof typedDocument.msHidden !== 'undefined') {
		hidden = 'msHidden';
		visibilityChange = 'msvisibilitychange';
	} else if (typeof typedDocument.webkitHidden !== 'undefined') {
		hidden = 'webkitHidden';
		visibilityChange = 'webkitvisibilitychange';
	}
	return [hidden, visibilityChange];
};

const sendPostMessageToParent = (data: unknown): void => {
	window.parent.postMessage(data, '*');
};
/**
 * Sends an object to the iFrame parent, if present
 * @param obj The object to be sent
 */
const sendMessage = (obj: unknown): void => {
	sendPostMessageToParent(obj);
};

const setupBrowserNotification = async () => {
	if ('Notification' in window) {
		notificationPermission = await Notification.requestPermission();
	}
	if (notificationPermission === 'granted') {
		const [hidden, visibilityChange] = getVisibilityChangeBrowserSupportDetails();
		// if supported.
		if (hidden && visibilityChange) {
			tabHidden = document.hidden;
			window.addEventListener(visibilityChange, () => {
				// document.hidden works on nearly all the latest versions of modern browsers. Hence, using it directly
				// but the visibility change does not provide good support on IOS.
				// hence, using the function and getting the respective similar event
				const hiddenStatus = document.hidden;
				tabHidden = hiddenStatus !== undefined ? hiddenStatus : false;
			});
		}
	}
};

const sendCloseNotification = (event: { keyCode?: number; mouseClick?: boolean }): void => {
	if (event.keyCode === 27 || event?.mouseClick) {
		sendPostMessageToParent({ type: 'closeWidget', close: true });
	}
};

const sendExpansionNotification = (): void => {
	sendPostMessageToParent({ type: 'expandWidget', expand: true });
};

const sendUnreadMessageNotification = (data: { title?: string; text?: string }): void => {
	if (StorageService.application === Applications.LIVE_AGENT_DASHBOARD) {
		if (notificationPermission === 'granted' && tabHidden) {
			// eslint-disable-next-line no-new
			new Notification(data.title ? data.title : '', { body: data.text });
		}
	} else {
		sendPostMessageToParent({ type: 'newMessage', ...data });
	}
};

const updateUnreadCount = (data: { count: number }): void => {
	sendPostMessageToParent({ type: 'updateUnreadCount', ...data });
};

export const PushNotificationService = {
	sendMessage,
	sendCloseNotification,
	sendExpansionNotification,
	sendUnreadMessageNotification,
	updateUnreadCount,
	setupBrowserNotification,
};
