/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { Chip } from '@mui/material';
import { STATUS } from '../../enums/status.enum';

export const IscChip = (props: { status: STATUS }) => {
	const { status } = props;
	return (
		<Chip
			label={status}
			sx={{
				background: status === STATUS.ACTIVE ? '#8fe6b3' : '#F8C9C4',
				color: status === STATUS.ACTIVE ? '#1B7943' : '#A82215',
				width: 120,
				height: 27,
			}}
		/>
	);
};
