/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import axios, { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import { Applications } from '../enums/applications.enum';
import { STATUS } from '../enums/status.enum';
import { USER_ROLES } from '../enums/user-roles.enum';
import {
	BotMessageRequestBody,
	Config,
	fetchConversationParams,
	fetchConversationResponse,
	fetchMessageTemplatesParams,
	fetchRoomParams,
	FetchTenantConfigResponse,
	fetchUserParams,
	GenericResponse,
	MessageTemplate,
	ParsedMessage,
	ParsedRoom,
	ParsedUser,
	RawBotResponse,
	RoomPopulated,
	ROOM_TYPES,
	User,
} from '../types';
import { SfxService } from './SfxService';
import { StorageService } from './StorageService';
import { Utils } from './Utils';

const API_URL = process.env.REACT_APP_API_URL as string;
if (!API_URL) {
	throw new Error('Environment variables are missing.');
}

const defaultConfig = {
	_id: '',
	name: '',
	brandingConfig: {
		avatar: 'https://cdn.ischoolconnect.com/chatbot/Casie_v3.svg',
		title: 'Chatbot',
		theme: {
			primary: '#00aff0',
			secondary: '#00aff0',
			tertiary: 'rgba(0, 175, 240, 0.4)',
		},
		iscBranding: true,
		favicon:
			'https://cdn.ischoolconnect.com/accounts/2fc52ed3-b5f8-4ddb-843c-3d7c7917f63d/favicon/favicon-144x144.png',
	},
};

// Axios
const axiosInstance = axios.create({
	baseURL: API_URL,
});
// Request transform
axiosInstance.interceptors.request.use(
	(config) => {
		// eslint-disable-next-line no-param-reassign
		config.headers = {
			...config.headers,
			Authorization: `Bearer ${config.headers.token ?? StorageService.accessToken}`,
			'x-tenant-api-key': StorageService.tenantApiKey,
			// eslint-disable-next-line no-restricted-globals
			'x-tenant-host': new URL(`${location.protocol}//${location.host}`).origin,
		};
		_.unset(config, 'headers.token');
		return config;
	},
	// eslint-disable-next-line no-console
	(error) => console.error('error in request interceptor', error),
);
// Response tranform
axiosInstance.interceptors.response.use(
	(response) => response,
	(error: AxiosError) => {
		if (
			StorageService.application === Applications.LIVE_AGENT_DASHBOARD &&
			error.response?.status === 401 &&
			!error.config.url?.includes('login')
		) {
			StorageService.clearAuthData();
			// eslint-disable-next-line no-restricted-globals
			location.href = '/login';
			SfxService.stopWaitingUserSound();
		}
		return Promise.reject(error);
	},
);

const fetchTenantConfig = async (auth: boolean, token?: string): Promise<Config> => {
	try {
		const response = await axiosInstance.get<FetchTenantConfigResponse>(
			auth ? 'tenant/self' : 'tenant/public',
			{
				headers: { token },
			},
		);
		const { data } = response.data;
		return {
			_id: data._id,
			name: data.name,
			brandingConfig: {
				avatar: data.branding_config.avatar,
				title: data.branding_config.bot_name,
				logo: data.branding_config.logo,
				theme: {
					primary: data.branding_config.theme.primary,
					secondary: data.branding_config.theme.secondary,
					tertiary: data.branding_config.theme.tertiary,
					elementFocus: data.branding_config.theme.element_focus,
				},
				iscBranding: data.branding_config.isc_branding,
				favicon: data.branding_config.favicon,
			},
			analyticsDashboardUrl: data.analytics_dashboard_url,
			identifier: data.identifier,
		};
	} catch (err) {
		return defaultConfig;
	}
};

const userParser = (user: User) => ({
	_id: user._id,
	firstName: user.first_name,
	lastName: user.last_name,
	tenant_id: user.tenant_id,
	email: user.email,
	role: user.role,
	status: user.status,
	passwordResetRequired: user.password_reset_required,
	externalUserIdentifier: user.external_user_identifier,
});

const fetchUserInfo = async (token?: string): Promise<ParsedUser> => {
	const response = await axiosInstance.get('user/self', {
		headers: {
			token,
		},
	});
	const { data } = response.data;
	return userParser(data);
};

const roomParser = (room: RoomPopulated): ParsedRoom | undefined => {
	let parsedRoom;
	const otherPerson = room.members_meta.find(
		(member) => member?._id !== StorageService?.authUser?._id || room?.guest_info,
	);
	const currentPerson = room.members.find(
		(member) => member?.user_id === StorageService?.authUser?._id,
	);
	const guest = room?.guest_info;
	// if room has ended, only one member will exist
	if ((otherPerson && currentPerson) || (currentPerson && guest)) {
		parsedRoom = {
			_id: room._id,
			title: guest?.name || `${otherPerson?.first_name} ${otherPerson?.last_name}`,
			imageUrl: otherPerson?.profile_image,
			lastMessage: room.last_message,
			lastMessageAt: room.last_message_at,
			unreadCount: Number.parseInt(currentPerson.unread_count, 10) || 0,
			status: otherPerson?.status,
			endedAt: room.ended_at,
		};
	}
	return parsedRoom;
};

const fetchRooms = async (fetchData?: {
	searchText?: string;
	activeRooms?: boolean;
	limit?: number;
	page?: number;
}): Promise<{ rooms: ParsedRoom[]; total: number }> => {
	let params: fetchRoomParams = {
		sort_key: 'last_message_at',
		sort_direction: 'desc',
		limit: 20,
		page: 1,
		has_ended: !fetchData?.activeRooms,
	};
	switch (StorageService.application) {
		case Applications.EXTERNAL_CHAT:
			params = { ...params, status: ROOM_TYPES.PERMANENT };
			break;
		case Applications.LIVE_AGENT_DASHBOARD:
			params = { ...params, status: ROOM_TYPES.LIVE_AGENT };
			break;
		default:
			throw Error('invalid application type');
	}
	if (fetchData?.searchText) {
		params = { ...params, search: Utils.sanitizeText(fetchData.searchText) };
	}
	if (fetchData?.limit) {
		params = { ...params, limit: fetchData.limit };
	}
	if (fetchData?.page) {
		params = { ...params, page: fetchData.page };
	}
	const response = await axiosInstance.get('room', { params });
	return {
		rooms: response?.data?.data?.map(roomParser),
		total: response?.data?.meta?.total,
	};
};

const fetchRoom = async (roomId: string): Promise<ParsedRoom | undefined> => {
	const response = await axiosInstance.get(`room/${roomId}`);
	return roomParser(response?.data?.data);
};

const assignSelf = async () => axiosInstance.post<{ room_id: string }>('room/assign-self', {});

const fetchConversation = async (
	room: ParsedRoom,
	lastMessageId?: string,
): Promise<ParsedMessage[]> => {
	const params: fetchConversationParams = { limit: 50 };
	if (lastMessageId) {
		params.last_message_id = lastMessageId;
	}
	const response = await axiosInstance.get<fetchConversationResponse>(`room/${room._id}/messages`, {
		params,
	});
	return response.data.data.map(Utils.parseFetchConversationResponse);
};

const messageBot = async (body: BotMessageRequestBody): Promise<RawBotResponse> => {
	const response = await axiosInstance.post('bot/message', body);
	return response.data as RawBotResponse;
};

const closeBot = async (roomId: string): Promise<AxiosResponse<GenericResponse>> => {
	const response = await axiosInstance.patch<GenericResponse>(`room/${roomId}/close`, {});
	return response;
};

const markRoomAsRead = async (roomId: string): Promise<GenericResponse | undefined> => {
	// kept this call asynchronously only as functionalities do not depend on it
	// TODO: figure out later if any action should be done if this API call throws an error
	try {
		return await axiosInstance.patch(`room/${roomId}/mark-as-read`);
	} catch (error) {
		// eslint-disable-next-line no-console
		console.log('failed to mark room as read', roomId);
		return undefined;
	}
};

interface LoginResponse extends AxiosResponse {
	data: {
		data: { access_token: string; expiration_time: number; token_type: 'Bearer' }[];
		message: string;
		statusCode: number;
	};
}

const login = (creds: { email: string; password: string }): Promise<LoginResponse> =>
	axiosInstance.post<{ data: { access_token: string } }>(
		'/auth/login',
		creds,
	) as unknown as Promise<LoginResponse>;

const forgotPassword = (email: string) => axiosInstance.post('/auth/forgot-password', { email });

const resetPassword = (password: string, authToken: string | null, resetToken?: string) => {
	const path = resetToken ? '' : 'auth';
	const body = resetToken
		? { password, token: resetToken }
		: {
				password,
		  };
	const options = resetToken ? {} : { headers: { token: authToken } };
	return axiosInstance.patch(`/auth/reset-password/${path}`, body, options);
};

const fetchUsers = async (data: {
	sortKey?: string;
	sortDirection?: string;
	searchText?: string | null;
	page: number;
	limit?: number;
	role?: USER_ROLES;
	status?: string;
}) => {
	const params: fetchUserParams = {
		page: data.page,
		limit: data.limit || 10,
	};
	if (data.sortKey) {
		params.sort_key = data.sortKey;
	}
	if (data.sortDirection) {
		// API calls required sortDirection in the UpperCase
		params.sort_direction = data.sortDirection.toUpperCase();
	}
	if (data.searchText) {
		params.search = data.searchText;
	}
	if (data.role) {
		params.role = data?.role;
	}
	const response = await axiosInstance.get<{
		data: User[];
		meta: { total: number };
		message: string;
	}>('/user', {
		params,
	});
	const parsedUsers = response.data.data.map(userParser);
	const index = parsedUsers.findIndex((user) => user._id === StorageService.authUser._id);
	if (index > -1) {
		parsedUsers.splice(index, 1);
	}
	return {
		data: parsedUsers,
		meta: response.data.meta,
		message: response.data.message,
	};
};

const transferChat = (roomId: string, userId: string) =>
	axiosInstance.patch(`room/${roomId}`, { user_id: userId });

const addAgent = (data: { email: string; firstName: string; lastName: string }) =>
	axiosInstance.post<{ message: string }>('/user', {
		first_name: data.firstName,
		last_name: data.lastName,
		email: data.email,
		role: USER_ROLES.AGENT,
	});

const editAgent = (data: {
	_id: string;
	firstName?: string;
	lastName?: string;
	status?: STATUS;
}) => {
	let dataToBeUpdated = {};
	if (data.firstName) {
		dataToBeUpdated = { ...dataToBeUpdated, first_name: data.firstName };
	}
	if (data.lastName) {
		dataToBeUpdated = { ...dataToBeUpdated, last_name: data.lastName };
	}
	if (data.status) {
		dataToBeUpdated = { ...dataToBeUpdated, status: data.status };
	}
	return axiosInstance.patch<{ message: string }>(`user/${data._id}`, dataToBeUpdated);
};

const closeRoom = (roomId: string) => axiosInstance.patch(`room/${roomId}/close`, {});

const fetchMessageTemplates = (paramsToBePassed: {
	sortKey?: string;
	sortDirection?: string;
	searchText?: string | null;
	page: number;
	limit: number;
}) => {
	const params: fetchMessageTemplatesParams = {
		page: paramsToBePassed.page,
		limit: paramsToBePassed.limit || 10,
	};
	if (paramsToBePassed.sortKey) {
		params.sort_key = paramsToBePassed.sortKey;
	}
	if (paramsToBePassed.sortDirection) {
		// API calls required sortDirection in the UpperCase
		params.sort_direction = paramsToBePassed.sortDirection.toUpperCase();
	}
	if (paramsToBePassed.searchText) {
		params.search = paramsToBePassed.searchText;
	}
	return axiosInstance.get<{
		data: MessageTemplate[];
		meta: { total: number };
		message: string;
	}>('/message-templates', {
		params,
	});
};

const addMessageTemplate = (data: { title: string; template: string }) =>
	axiosInstance.post<{ message: string }>('/message-templates', data);

const updateMessageTemplate = (data: {
	title?: string;
	template?: string;
	status?: STATUS;
	_id: string;
}) => {
	let dataToBeUpdated = {};
	if (data.title) {
		dataToBeUpdated = { ...dataToBeUpdated, title: data.title };
	}
	if (data.template) {
		dataToBeUpdated = { ...dataToBeUpdated, template: data.template };
	}
	if (data.status) {
		dataToBeUpdated = { ...dataToBeUpdated, status: data.status };
	}
	return axiosInstance.patch<{ message: string }>(
		`/message-templates/${data._id}`,
		dataToBeUpdated,
	);
};

const validateResetPasswordToken = async (resetToken: string) => {
	const response = axiosInstance.post('/auth/forgot-password/verify-token', { token: resetToken });
	return (await response).data?.data?.verified || false;
};

const downloadChat = (roomId: string) =>
	axiosInstance.get(`room/download/conversation/${roomId}`, {
		responseType: 'blob',
	});

export const ApiService = {
	fetchTenantConfig,
	fetchUserInfo,
	fetchUsers,
	transferChat,
	fetchRooms,
	fetchRoom,
	fetchConversation,
	messageBot,
	closeBot,
	markRoomAsRead,
	login,
	forgotPassword,
	resetPassword,
	addAgent,
	editAgent,
	assignSelf,
	closeRoom,
	fetchMessageTemplates,
	addMessageTemplate,
	updateMessageTemplate,
	validateResetPasswordToken,
	downloadChat,
};
