/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { FC, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { USER_ROLES } from '../enums/user-roles.enum';
import { STATUS } from '../enums/status.enum';
import { StorageService } from '../services/StorageService';

interface props {
	role?: USER_ROLES | null;
	children?: ReactElement | null;
	defaultComponent?: boolean;
}

const AuthorizedRoute: FC<props> = ({ children, role, defaultComponent }: props) => {
	const { authUser } = StorageService;
	let elementToBeRendered = null;
	// Default
	if (defaultComponent) {
		elementToBeRendered =
			authUser?.role === USER_ROLES.ADMIN ? <Navigate to="./agents" /> : <Navigate to="./chats" />;
	}
	// Authorize
	if (role && authUser.role === role && authUser.status !== STATUS.DISABLED && children) {
		elementToBeRendered = children;
	}
	return elementToBeRendered || <Navigate to="../" />;
};

AuthorizedRoute.defaultProps = {
	role: null,
	children: null,
	defaultComponent: false,
};

export default AuthorizedRoute;
