/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
	setSearchData: (search: string) => void;
	searchData: string | null;
	placeholder: string;
	width?: string;
	maxLength?: number;
}

const Searchbox = ({ setSearchData, searchData, placeholder, width, maxLength }: Props) => {
	const inputProps = {
		maxLength,
	};

	return (
		<OutlinedInput
			sx={{ flex: 1, width, m: '1rem auto' }}
			placeholder={placeholder}
			onChange={(e) => setSearchData(e.target.value)}
			value={searchData}
			inputProps={inputProps}
			autoFocus
			endAdornment={
				searchData && (
					<InputAdornment position="end">
						<IconButton onClick={() => setSearchData('')}>
							<CloseIcon />
						</IconButton>
					</InputAdornment>
				)
			}
		/>
	);
};

Searchbox.defaultProps = {
	width: '100%',
	maxLength: 50,
};
export default Searchbox;
