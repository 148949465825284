/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { useForm, Controller } from 'react-hook-form';
import { useState } from 'react';
import {
	Box,
	createTheme,
	CssBaseline,
	Grid,
	Link,
	Paper,
	TextField,
	ThemeProvider,
	Typography,
	Button,
} from '@mui/material';
import { emailRegex } from '../../../configs';
import { ApiService } from '../../../services/ApiService';
import Toast from '../../Toast/Toast';
import { ToastProperties } from '../../../types';
import { MESSAGES } from '../../../messages';
import { LoggerService } from '../../../services/LoggerService';

export const ForgotPassword = () => {
	// States
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<{ email: string }>({
		defaultValues: {
			email: '',
		},
	});
	const [toastProperties, setToastProperties] = useState<ToastProperties>({
		open: false,
		isError: false,
		message: '',
	});
	const theme = createTheme();

	// API calls
	const forgetPassword = handleSubmit(async (data) => {
		try {
			setIsLoading(true);
			const response = await ApiService.forgotPassword(data.email);
			setToastProperties({ message: response.data.message, open: true, isError: false });
		} catch (error) {
			setToastProperties({ message: MESSAGES.GENERIC_ERROR, open: true, isError: false });
			LoggerService.logError(error, 'forgot password error');
		} finally {
			setIsLoading(false);
		}
	});

	return (
		<ThemeProvider theme={theme}>
			<Grid container component="main" sx={{ height: '100vh' }}>
				<CssBaseline />
				<Grid
					item
					xs={false}
					sm={4}
					md={7}
					sx={{
						backgroundImage: 'url(https://cdn.ischoolconnect.com/chatbot/live-agent-welcome.svg)',
						backgroundRepeat: 'no-repeat',
						backgroundColor: (t) =>
							t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
						backgroundSize: 'cover',
						backgroundPosition: 'center',
					}}
				/>
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}>
						<Typography component="h1" variant="h5">
							Forgot Password
						</Typography>
						<Box component="form" noValidate onSubmit={forgetPassword} sx={{ mt: 1 }}>
							<Controller
								name="email"
								control={control}
								rules={{ required: true, pattern: emailRegex, maxLength: 191 }}
								render={({ field }) => (
									<TextField
										margin="normal"
										required
										fullWidth
										id="email"
										label="Email Address"
										name="email"
										autoComplete="email"
										autoFocus
										value={field.value}
										onChange={(em) => field.onChange(em.target.value)}
										error={!!errors.email?.type || false}
										helperText={
											(errors.email?.type === 'required' &&
												'Please enter your registered email ID') ||
											(errors.email?.type === 'pattern' && 'Please enter a valid email ID')
										}
									/>
								)}
							/>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 2, textTransform: 'capitalize', background: '#00AFF0' }}
								disabled={isLoading}>
								{isLoading ? 'Loading...' : 'Reset Password'}
							</Button>
							<Grid container>
								<Grid item xs>
									<Link href="/login" variant="body2">
										Login
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Grid>
				<Toast
					open={toastProperties.open}
					isError={toastProperties.isError}
					message={toastProperties.message}
					onClose={() => setToastProperties({ ...toastProperties, open: false })}
				/>
			</Grid>
		</ThemeProvider>
	);
};
