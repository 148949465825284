/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { Alert, Box, Button, Dialog, DialogTitle, IconButton, List, ListItem } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { AxiosError } from 'axios';
import { ApiService } from '../../services/ApiService';
import { Utils } from '../../services/Utils';
import { MessageTemplate, ToastProperties } from '../../types';
import './Message-template-select.css';
import { Loader } from '../Loader/Loader';
import useDebounce from '../../hooks/useDebounce';
import Searchbox from '../searchbox/Searchbox';
import Toast from '../Toast/Toast';
import { MESSAGES } from '../../messages';

interface props {
	open: boolean;
	closePopover: () => void;
	onTemplateSelect: (selectedTemplate: string) => void;
}
const MessageTemplateSelect: FC<props> = ({ open, closePopover, onTemplateSelect }: props) => {
	const [paginatedLoading, setPaginatedLoading] = useState<boolean>(false);
	const [loading, setIsLoading] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [templatesToBeRendered, setTemplatesToBeRendered] = useState<MessageTemplate[]>([]);
	const [selectedTemplate, setSelectedTemplate] = useState<MessageTemplate>();
	const [total, setTotal] = useState<number>();
	const [searchData, setSearchData] = useState<string | null>(null);
	const debouncedSearchText = useDebounce(searchData, 1000);
	const [toastProperties, setToastProperties] = useState<ToastProperties>({
		open: false,
		message: '',
		isError: false,
	});

	const fetchTemplates = async (data?: { searchText?: string; page?: number }) => {
		try {
			// gather data
			const currentPage = data?.page || page;
			const searchText = data?.searchText || debouncedSearchText;
			// run loaders
			if (currentPage > 0) {
				setPaginatedLoading(true);
			} else {
				setIsLoading(true);
			}
			// API call
			const response = await ApiService.fetchMessageTemplates({
				searchText,
				page: currentPage + 1,
				limit: 10,
			});
			if (currentPage > 0) {
				setTemplatesToBeRendered((templates) => templates.concat(response.data.data));
			} else {
				setTemplatesToBeRendered(response.data.data);
			}
			setTotal(response.data.meta.total);
		} catch (error) {
			const typedError = error as AxiosError;
			const message =
				typedError.response?.status === 404
					? typedError.response.data.message
					: MESSAGES.GENERIC_ERROR;
			setToastProperties({ open: true, message, isError: true });
		} finally {
			setPaginatedLoading(false);
			setIsLoading(false);
		}
	};

	const onSearch = (search: string | null) => {
		setSearchData(search);
	};

	useEffect(() => {
		fetchTemplates({ page: 0 });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setPage(0);
		if (debouncedSearchText) {
			fetchTemplates({ searchText: debouncedSearchText, page: 0 });
		} else {
			fetchTemplates({ page: 0 });
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchText]);

	return (
		<>
			<Toast
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...toastProperties}
				onClose={() => setToastProperties({ ...toastProperties, open: false })}
			/>
			<Dialog fullWidth maxWidth="sm" id={Utils.unique()} open={open} onClose={closePopover}>
				<DialogTitle
					sx={{
						borderBottom: '1px solid #CBD9E6',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}>
					Quick Replies
					<IconButton onClick={() => closePopover()}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<Searchbox
					searchData={searchData}
					setSearchData={onSearch}
					placeholder="Search quick-replies"
					width="95%"
				/>
				{loading && (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							pt: 10,
							pb: 15,
						}}>
						<Loader size="regular" />
					</Box>
				)}
				{!loading && templatesToBeRendered?.length !== 0 && (
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<List sx={{ height: 400, minWidth: 200, width: '20%' }}>
							{templatesToBeRendered?.map((template) => (
								<ListItem
									className={template._id === selectedTemplate?._id ? 'selected-template' : ''}
									key={template._id}
									onClick={() => setSelectedTemplate(template)}
									sx={{ borderRight: '1px solid #CBD9E6' }}>
									{template.title}
								</ListItem>
							))}
							{total && (templatesToBeRendered?.length || 0) < total && (
								<ListItem
									className="center-text-align"
									onClick={() => {
										setPage((currentPage) => {
											fetchTemplates({ page: currentPage + 1 });
											return currentPage + 1;
										});
									}}
									sx={{
										borderRight: '1px solid #CBD9E6',
										justifyContent: 'center',
										alignItems: 'center',
									}}>
									{paginatedLoading ? <Loader size="small" /> : 'Show More'}
								</ListItem>
							)}
						</List>
						<Box
							sx={{
								width: '100%',
								overflowX: 'hidden',
								wordBreak: 'break-word',
								p: '1rem 1.5rem',
							}}>
							{selectedTemplate ? (
								<>
									{selectedTemplate.template}
									<Button
										variant="contained"
										sx={{
											position: 'absolute',
											bottom: '1rem',
											right: '1rem',
											textTransform: 'capitalize',
										}}
										className="use-reply-button"
										onClick={() => onTemplateSelect(selectedTemplate?.template)}>
										Use Reply
									</Button>
								</>
							) : (
								'No Template selected'
							)}
						</Box>
					</Box>
				)}
				{!paginatedLoading && !loading && !templatesToBeRendered?.length && (
					<Alert severity="error" sx={{ m: 4, mt: 6, mb: 6 }}>
						{' '}
						No Quick Reply Templates{' '}
					</Alert>
				)}
			</Dialog>
		</>
	);
};

export default MessageTemplateSelect;
