/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { FC, useEffect, useState } from 'react';
import { Box, Alert } from '@mui/material';
import { ApiService } from '../../services/ApiService';
import { LoggerService } from '../../services/LoggerService';
import { Loader } from '../Loader/Loader';

const Analytics: FC = () => {
	const [isLoading, setIsLoading] = useState<boolean>();
	const [analyticsUrl, setAnalyticsUrl] = useState<string>('');
	const fetchDashboardUrl = async () => {
		try {
			setIsLoading(true);
			const tenant = await ApiService.fetchTenantConfig(true);
			if (tenant.analyticsDashboardUrl) {
				setAnalyticsUrl(tenant.analyticsDashboardUrl);
			}
		} catch (error) {
			LoggerService.logError(error, 'failed to fetch tenant data for analytics');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchDashboardUrl();
	}, []);

	return (
		<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 3 }}>
			{isLoading && <Loader size="regular" />}
			{!isLoading && !analyticsUrl && (
				<Alert sx={{ m: 20 }} severity="error">
					Sorry, no dashboard found
				</Alert>
			)}
			{!isLoading && analyticsUrl && (
				<iframe
					title="analytics"
					height="1000px"
					width="100%"
					src={analyticsUrl}
					frameBorder="0"
					allowFullScreen
				/>
			)}
		</Box>
	);
};

export default Analytics;
